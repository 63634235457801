import { Component } from '@angular/core'
import { MiscFunctions } from 'src/app/misc'
import { ConfigService } from 'src/app/services/config.service'

@Component({
  selector: 'cui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  metadata
  currentYear: number = new Date().getFullYear()

  version = `${this.config.getProgramVersion()}${
    this.config.getConfig().program == 'ardent' || this.config.getConfig().program == 'ardent-bmm'
      ? ''
      : this.config.getVersion()
  }`
  constructor(private config: ConfigService, public misc: MiscFunctions) {
    this.metadata = this.config.getMetadataConfig()
  }
}
