import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import { ConfigService } from '../config.service'

@Injectable()
export class reportService {
  constructor(
    private http: CustomHttpService,
    private router: Router,
    private config: ConfigService,
  ) {}

  CMSDetails(data?): Observable<any> {
    let cms = this.config.getCMS()
    return this.http.getv2(`dashboard/RetrieveCMSDetails`, {
      params: {
        filter: `CMS_CODE|${cms['code']}:CMS_VERSION|${cms['version']}`,
        startdate: '',
        enddate: '',
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: '',
        sortType: '',
      },
    })
  }

  getReportWageringBalanceSummaryOverall(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WageringBalanceSummaryOverall`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWageringDetailedReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WageringDetailedReportPerAccount`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWageringBalanceSummaryAccount(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WageringBalanceSummaryPerAccount`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportPlayerAccountSummary(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/PlayerAccountSummary`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportPlayerAccountDetailed(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/PlayerAccountDetails`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportLiability(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/LiabilityReports`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportLiabilityNew(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    let url =
      data.payload.type == 'DETAILED'
        ? 'InternalReport/LiabilityReports'
        : 'InternalReport/LiabilitySummaryReports'
    return this.http.getv2(url, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options && data.payload.type == 'DETAILED' ? options.enddate : '',
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportCSD(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/CashierDetailedReports`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportSA(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/SystemAlterationLog`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWATDetail(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WatDetailReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWATSummaryGaming(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WatSummaryReportByGamingArea`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWATSummaryOverall(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WatSummaryReportOverall`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportUserAccessLogs(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/UserAccessLogs`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportEL(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/DataAlterationLog`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getTransactionSessionReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/TransactionSessionReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: 'accountId',
        sortType: data.payload.sortType,
      },
    })
  }
  getPatronInfoReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/PatronInfoSessionReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn ? data.payload.sortColumn : 'accountId',
        sortType: data.payload.sortType,
      },
    })
  }
  getPlayerActivityReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    let url =
      data.payload.type == 'DAILY_REPORT'
        ? 'InternalReport/ActivityReports'
        : data.payload.type == 'SUMMARY'
        ? 'InternalReport/ActivitySummaryReports'
        : 'InternalReport/DetailedPlayerActivityReports'
    return this.http.getv2(url, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: data.payload.type == 'DETAILED' ? options.enddate : '',
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWatInRecon(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WatInReconReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportWatOutRecon(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/WatOutReconReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportFailedTransactions(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/FailedTransactionReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }

  getReportSystemErrorsandExceptions(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/SystemErrorAndExceptionReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getPlayerStatusReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`internalReport/PlayerStatusChangeReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportAuditTrail(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate() + 1)
    return this.http.getv2(`InternalReport/AuditTrailReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getLimitActivityReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate() + 1)
    return this.http.getv2(`InternalReport/LimitActivityReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getReportCME(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let startdate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/CashlessMonthEndReconReport`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : startdate,
        enddate: '',
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  getCashierTransactionReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/CashierTransactionReports`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }

  getCashierSummaryReport(data?): Observable<any> {
    let date = new Date()
    let options = data.payload.options
    let enddate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    return this.http.getv2(`InternalReport/CashierSummaryReports`, {
      params: {
        filter: options ? options.filter : '',
        value: options ? options.value : '',
        startdate: options ? options.startdate : '1970-1-1',
        enddate: options ? options.enddate : enddate,
        page: data.payload.page,
        limit: data.payload.limit,
        sortColumn: data.payload.sortColumn,
        sortType: data.payload.sortType,
      },
    })
  }
  returnCheck(data?): Observable<any> {
    return this.http.postv2(`Dashboard/cancel/pending/payment`, data)
  }
  processCheck(id, data?): Observable<any> {
    return this.http.postv2(`Dashboard/PostPendingCheckPayment/${id}`, data)
  }
}
