import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import session from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import * as moment from 'moment'

@Injectable()
export class userMenuService {
  constructor(private http: CustomHttpService, private router: Router) {}

  updateProfile(userData): Observable<any> {
    return this.http.put(`user/UpdateUserDetailsById`, {
      userId: userData.userId,
      firstName: userData.firstName.trim(),
      lastName: userData.lastName.trim(),
      mobile: userData.mobile
        .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
        .replace(/\s+/g, ''),
      userRoleId: userData.userRoleId,
      title: userData.title,
      badgeNumber: userData.badgeNumber,
      dob: userData.dob ? moment(userData.dob).format('YYYY-MM-DD') : '',
      status: 'active',
    })
  }

  updatePassword(passData): Observable<any> {
    let data = {
      newPassword: passData.newPassword,
      oldPassword: passData.oldPassword,
    }
    return this.http.put(`user/ChangePassword`, JSON.stringify(data))
  }
}
