import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as act from './actions'
import { reportService } from 'src/app/services/report'
import * as moment from 'moment'
import { CurrencyPipe, PercentPipe } from '@angular/common'
import { MiscFunctions } from 'src/app/misc'
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast'

@Injectable()
export class ReportEffects {
  CMSDetailsData: [] = []
  reportWageringBalanceSummaryOverall: [] = []
  reportWageringDetailedReport: [] = []
  reportWageringBalanceSummaryAccount: [] = []
  reportPlayerAccountSummary: [] = []
  reportPlayerAccountDetailed: [] = []
  reportLiability: [] = []
  reportCSD: [] = []
  reportSA: [] = []
  reportWATDetail: [] = []
  reportWATSummaryGaming: [] = []
  reportWATSummaryOverall: [] = []
  reportUserAccessLogs: [] = []
  reportEL: [] = []
  reportWatInRecon: [] = []
  reportSystemErrorsandExceptions: [] = []
  reportWatOutRecon: [] = []
  reportFailedTransactions: [] = []
  reportAuditTrail: [] = []
  reportCME: [] = []
  reportCTR: [] = []
  reportLimitActivity: [] = []
  reportPatronInfo: [] = []
  reportTransactionSession: [] = []
  playerStatusChangeReport: [] = []
  reportCashierSummaryReport: [] = []
  CSROverallData = []
  newCSROverallData = []
  multipleReportTabs = {
    ReportLiability: {},
    PlayerActivity: {},
    CashierSummary: {},
  }
  tabType = ''

  constructor(
    private actions: Actions,
    private reportService: reportService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    public misc: MiscFunctions,
  ) {}

  @Effect()
  CMSDetails: Observable<any> = this.actions.pipe(
    ofType(act.CMS_DETAILS),
    map((action: act.CMSDetails) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.CMSDetails(payload).pipe(
        map(res => {
          res.data[0].cmsDetails.cmsTransactionsList.map(v => {
            v.FIRST_NAME = typeof v.FIRST_NAME != 'object' ? v.FIRST_NAME : ''
            v.LAST_NAME = typeof v.LAST_NAME != 'object' ? v.LAST_NAME : ''
            v.TRANSACTION_TYPE = typeof v.TYPE != 'object' ? v.TYPE.replace('_', ' ') : ''
            v.PROPERTY_ID = typeof v.PROPERTY_ID != 'object' ? v.PROPERTY_ID : ''
            v.AMOUNT = typeof v.AMOUNT != 'object' ? v.AMOUNT : ''
            v.DATE_TRANSACTION = typeof v.DATE_TRANSACTION != 'object' ? v.DATE_TRANSACTION : ''
            v.PLAYER_CARD_NUMBER =
              typeof v.PLAYER_CARD_NUMBER != 'object' ? v.PLAYER_CARD_NUMBER : ''
            v.PROPERTY_NAME = typeof v.PROPERTY_NAME != 'object' ? v.PROPERTY_NAME : ''
            v.STATUS = v.STATUS == 'A' ? 'Active' : v.STATUS == 'P' ? 'Pending' : 'Cancelled'
            v.AMOUNT = this.currencyPipe.transform(v.AMOUNT, 'USD', 'symbol', '1.2-2')
          })
          this.CMSDetailsData = []
          this.CMSDetailsData['CMSDetails'] = res.data[0].cmsDetails
          return new act.CMSDetailsSuccess(this.CMSDetailsData)
        }),
      )
    }),
  )
  @Effect()
  getReportWageringBalanceSummaryOverall: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAGERING_BALANCE_SUMMARY_OVERALL),
    map((action: act.ReportWageringBalanceSummaryOverall) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWageringBalanceSummaryOverall(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.BEGINNING_BALANCE = typeof v.BEGINNING_BALANCE !== 'object' ? v.BEGINNING_BALANCE : 0
            v.CREDIT = typeof v.CREDIT !== 'object' ? v.CREDIT : 0
            v.DEBIT = typeof v.DEBIT !== 'object' ? v.DEBIT : 0
            v.ENDING_BALANCE = typeof v.ENDING_BALANCE !== 'object' ? v.ENDING_BALANCE : 0
            v.SOURCE_OF_BALANCE = typeof v.SOURCE_OF_BALANCE !== 'object' ? v.SOURCE_OF_BALANCE : ''
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''

            v.CREDIT = this.currencyPipe.transform(v.CREDIT, 'USD', 'symbol', '1.2-2')
            v.DEBIT = this.currencyPipe.transform(v.DEBIT, 'USD', 'symbol', '1.2-2')
            v.BEGINNING_BALANCE = this.currencyPipe.transform(
              v.BEGINNING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.ENDING_BALANCE = this.currencyPipe.transform(
              v.ENDING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportWageringBalanceSummaryOverall = []
          this.reportWageringBalanceSummaryOverall['ReportWageringBalanceSummaryOverall'] = res
          return new act.ReportWageringBalanceSummaryOverallSuccess(
            this.reportWageringBalanceSummaryOverall,
          )
        }),
      )
    }),
  )
  @Effect()
  getReportWageringDetailedReport: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAGERING_DETAILED_REPORT),
    map((action: act.ReportWageringDetailedReport) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWageringDetailedReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.ACCOUNT_NUMBER = typeof v.ACCOUNT_NUMBER !== 'object' ? v.ACCOUNT_NUMBER : ''
            v.FIRST_NAME = typeof v.FIRST_NAME !== 'object' ? v.FIRST_NAME : ''
            v.LAST_NAME = typeof v.LAST_NAME !== 'object' ? v.LAST_NAME : ''
            v.BEGINNING_BALANCE = typeof v.BEGINNING_BALANCE !== 'object' ? v.BEGINNING_BALANCE : 0
            v.TRANSACTION_TIMESTAMP =
              typeof v.TRANSACTION_TIMESTAMP !== 'object' ? v.TRANSACTION_TIMESTAMP : ''
            v.PAYMENT_METHOD = typeof v.PAYMENT_METHOD !== 'object' ? v.PAYMENT_METHOD : ''
            v.GAMING_AREA = typeof v.GAMING_AREA !== 'object' ? v.GAMING_AREA : ''
            v.INITIATED_BY = typeof v.INITIATED_BY !== 'object' ? v.INITIATED_BY : ''
            v.CREDIT = typeof v.CREDIT !== 'object' ? v.CREDIT : 0
            v.DEBIT = typeof v.DEBIT !== 'object' ? v.DEBIT : 0
            v.TOTAL_CREDIT = typeof v.TOTAL_CREDIT !== 'object' ? v.TOTAL_CREDIT : 0
            v.TOTAL_DEBIT = typeof v.TOTAL_DEBIT !== 'object' ? v.TOTAL_DEBIT : 0
            v.ENDING_BALANCE = typeof v.ENDING_BALANCE !== 'object' ? v.ENDING_BALANCE : 0

            v.CREDIT = this.currencyPipe.transform(v.CREDIT, 'USD', 'symbol', '1.2-2')
            v.DEBIT = this.currencyPipe.transform(v.DEBIT, 'USD', 'symbol', '1.2-2')
            v.TOTAL_CREDIT = this.currencyPipe.transform(v.TOTAL_CREDIT, 'USD', 'symbol', '1.2-2')
            v.TOTAL_DEBIT = this.currencyPipe.transform(v.TOTAL_DEBIT, 'USD', 'symbol', '1.2-2')
            v.BEGINNING_BALANCE = this.currencyPipe.transform(
              v.BEGINNING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.ENDING_BALANCE = this.currencyPipe.transform(
              v.ENDING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportWageringDetailedReport = []
          this.reportWageringDetailedReport['ReportWageringDetailedReport'] = res
          return new act.ReportWageringDetailedReportSuccess(this.reportWageringDetailedReport)
        }),
      )
    }),
  )
  @Effect()
  getReportWageringBalanceSummaryAccount: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT),
    map((action: act.ReportWageringBalanceSummaryAccount) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWageringBalanceSummaryAccount(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.ACCOUNT_NUMBER = typeof v.ACCOUNT_NUMBER !== 'object' ? v.ACCOUNT_NUMBER : ''
            v.BEGINNING_BALANCE = typeof v.BEGINNING_BALANCE !== 'object' ? v.BEGINNING_BALANCE : 0
            v.CASH_IN = typeof v.CASH_IN !== 'object' ? v.CASH_IN : 0
            v.CASH_OUT = typeof v.CASH_OUT !== 'object' ? v.CASH_OUT : 0
            v.ENDING_BALANCE = typeof v.ENDING_BALANCE !== 'object' ? v.ENDING_BALANCE : 0
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''

            v.CASH_IN = this.currencyPipe.transform(v.CASH_IN, 'USD', 'symbol', '1.2-2')
            v.CASH_OUT = this.currencyPipe.transform(v.CASH_OUT, 'USD', 'symbol', '1.2-2')
            v.BEGINNING_BALANCE = this.currencyPipe.transform(
              v.BEGINNING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.ENDING_BALANCE = this.currencyPipe.transform(
              v.ENDING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportWageringBalanceSummaryAccount = []
          this.reportWageringBalanceSummaryAccount['ReportWageringBalanceSummaryAccount'] = res
          return new act.ReportWageringBalanceSummaryAccountSuccess(
            this.reportWageringBalanceSummaryAccount,
          )
        }),
      )
    }),
  )
  @Effect()
  getReportPlayerAccountSummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACCOUNT_SUMMARY),
    map((action: act.ReportPlayerAccountSummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPlayerAccountSummary(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.ACCOUNT_NUMBER = typeof v.ACCOUNT_NUMBER !== 'object' ? v.ACCOUNT_NUMBER : ''
            v.BEGINNING_BALANCE = typeof v.BEGINNING_BALANCE !== 'object' ? v.BEGINNING_BALANCE : 0
            v.ENDING_BALANCE = typeof v.ENDING_BALANCE !== 'object' ? v.ENDING_BALANCE : 0
            v.FUNDING_SOURCE = typeof v.FUNDING_SOURCE !== 'object' ? v.FUNDING_SOURCE : ''
            v.FIRST_NAME = typeof v.FIRST_NAME !== 'object' ? v.FIRST_NAME : ''
            v.LAST_NAME = typeof v.LAST_NAME !== 'object' ? v.LAST_NAME : ''
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''
            v.BEGINNING_BALANCE = this.currencyPipe.transform(
              v.BEGINNING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.ENDING_BALANCE = this.currencyPipe.transform(
              v.ENDING_BALANCE,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportPlayerAccountSummary = []
          this.reportPlayerAccountSummary['ReportPlayerAccountSummary'] = res
          return new act.ReportPlayerAccountSummarySuccess(this.reportPlayerAccountSummary)
        }),
      )
    }),
  )
  @Effect()
  getReportPlayerAccountDetailed: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACCOUNT_DETAILED),
    map((action: act.ReportPlayerAccountDetailed) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportPlayerAccountDetailed(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.ACCOUNT_NUMBER = typeof v.ACCOUNT_NUMBER !== 'object' ? v.ACCOUNT_NUMBER : ''
            v.FIRST_NAME = typeof v.FIRST_NAME !== 'object' ? v.FIRST_NAME : ''
            v.LAST_NAME = typeof v.LAST_NAME !== 'object' ? v.LAST_NAME : ''
            v.GAMING_MACHINE_NUMBER =
              typeof v.GAMING_MACHINE_NUMBER !== 'object' ? v.GAMING_MACHINE_NUMBER : ''
            v.AMOUNT = typeof v.AMOUNT !== 'object' ? v.AMOUNT : 0
            v.FUNDING_SOURCE = typeof v.FUNDING_SOURCE !== 'object' ? v.FUNDING_SOURCE : ''
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''

            v.AMOUNT = this.currencyPipe.transform(v.AMOUNT, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportPlayerAccountDetailed = []
          this.reportPlayerAccountDetailed['ReportPlayerAccountDetailed'] = res
          return new act.ReportPlayerAccountDetailedSuccess(this.reportPlayerAccountDetailed)
        }),
      )
    }),
  )
  @Effect()
  getReportLiability: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIABILITY),
    map((action: act.ReportLiability) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLiability(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.patronId = typeof v.patronId !== 'object' ? v.patronId : ''
            v.walletType = typeof v.walletType !== 'object' ? v.walletType : ''
            v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
            v.transactionAmount = typeof v.transactionAmount !== 'object' ? v.transactionAmount : 0
            v.beginningBalance = typeof v.beginningBalance !== 'object' ? v.beginningBalance : 0
            v.endingBalance = typeof v.endingBalance !== 'object' ? v.endingBalance : 0
            v.gt_cin = v.grandTotal_cashlessIn
            v.gt_cout = v.grandTotal_cashlessOut
            v.transactionDateTime =
              typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''

            v.totalCashlessIn = this.currencyPipe.transform(
              v.totalCashlessIn,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.grandTotal_cashlessIn = this.currencyPipe.transform(
              v.grandTotal_cashlessIn,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.grandTotal_cashlessOut = this.currencyPipe.transform(
              v.grandTotal_cashlessOut,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.totalCashlessOut = this.currencyPipe.transform(
              v.totalCashlessOut,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportLiability = []
          this.reportLiability['ReportLiability']['Old'] = res
          return new act.ReportLiabilitySuccess(this.reportLiability)
        }),
      )
    }),
  )
  @Effect()
  getReportLiabilityNew: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIABILITY_NEW),
    map((action: act.ReportLiabilityNew) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportLiabilityNew(payload).pipe(
        map(res => {
          this.tabType = payload.payload.type
          if (this.tabType == 'SUMMARY') {
            res.data = [
              {
                CATEGORY: 'Beginning Cashless Liability (Prior Day)',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].beginningCashlessLiability.CURRENT_DAY_TOTAL)
                  ? res.data[0].beginningCashlessLiability.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].beginningCashlessLiability.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(
                  res.data[0].beginningCashlessLiability.MONTH_TO_DATE_TOTALS,
                )
                  ? res.data[0].beginningCashlessLiability.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].beginningCashlessLiability.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(
                  res.data[0].beginningCashlessLiability.PREVIOUS_YEAR_TOTALS,
                )
                  ? res.data[0].beginningCashlessLiability.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].beginningCashlessLiability.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(
                  res.data[0].beginningCashlessLiability.YEAR_TO_DATE_TOTALS,
                )
                  ? res.data[0].beginningCashlessLiability.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].beginningCashlessLiability.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Cashless-In',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalCashlessIn.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalCashlessIn.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessIn.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Cashless-Out',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalCashlessOut.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalCashlessOut.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessOut.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlessOut.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalCashlessOut.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessOut.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Ending Cashless Liability',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].endingCashlessLiability.CURRENT_DAY_TOTAL)
                  ? res.data[0].endingCashlessLiability.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].endingCashlessLiability.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(
                  res.data[0].endingCashlessLiability.MONTH_TO_DATE_TOTALS,
                )
                  ? res.data[0].endingCashlessLiability.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].endingCashlessLiability.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(
                  res.data[0].endingCashlessLiability.PREVIOUS_YEAR_TOTALS,
                )
                  ? res.data[0].endingCashlessLiability.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].endingCashlessLiability.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].endingCashlessLiability.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].endingCashlessLiability.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].endingCashlessLiability.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
            ]
          } else {
            res.data = res.data.map(v => {
              // if data retrieved is object, return to 0
              v.patronId = typeof v.patronId !== 'object' ? v.patronId : ''
              v.walletType = typeof v.walletType !== 'object' ? v.walletType : ''
              v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
              v.transactionAmount =
                typeof v.transactionAmount !== 'object' ? v.transactionAmount : 0
              v.beginningBalance = typeof v.beginningBalance !== 'object' ? v.beginningBalance : 0
              v.endingBalance = typeof v.endingBalance !== 'object' ? v.endingBalance : 0
              v.transactionDateTime =
                typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''

              v.totalCashlessIn = this.currencyPipe.transform(
                v.totalCashlessIn,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.totalCashlessOut = this.currencyPipe.transform(
                v.totalCashlessOut,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_cin = this.currencyPipe.transform(
                v.grandTotal_cashlessIn,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_cout = this.currencyPipe.transform(
                v.grandTotal_cashlessOut,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })
          }
          this.multipleReportTabs.ReportLiability = {}
          this.multipleReportTabs.ReportLiability[this.tabType] = res
          return new act.ReportLiabilityNewSuccess(this.multipleReportTabs)
        }),
      )
    }),
  )
  @Effect()
  getReportCSD: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CSD),
    map((action: act.ReportCSD) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCSD(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.employeeId = typeof v.employeeId !== 'object' ? v.employeeId : ''
            v.patronId = typeof v.patronId !== 'object' ? v.patronId : ''
            v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
            v.transactionAmount = typeof v.transactionAmount !== 'object' ? v.transactionAmount : 0
            v.transactionDateTime =
              typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''

            v.transactionAmount = this.currencyPipe.transform(
              v.transactionAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportCSD = []
          this.reportCSD['ReportCSD'] = res
          return new act.ReportCSDSuccess(this.reportCSD)
        }),
      )
    }),
  )
  @Effect()
  getReportSA: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_SA),
    map((action: act.ReportSA) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportSA(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.systemAlterationType =
              typeof v.systemAlterationType !== 'object' ? v.systemAlterationType : ''
            v.systemAlterationLog =
              typeof v.systemAlterationLog !== 'object' ? v.systemAlterationLog : ''
            v.dataValueBeforeAlteration =
              typeof v.dataValueBeforeAlteration !== 'object' ? v.dataValueBeforeAlteration : ''
            v.dataValueAfterAlteration =
              typeof v.dataValueAfterAlteration !== 'object' ? v.dataValueAfterAlteration : ''
            v.logTimeStamp = typeof v.logTimeStamp !== 'object' ? v.logTimeStamp : ''
            v.alterationOwner = typeof v.alterationOwner !== 'object' ? v.alterationOwner : ''

            v.transactionAmount = this.currencyPipe.transform(
              v.transactionAmount,
              'USD',
              'symbol',
              '1.2-2',
            )

            v.dataValueAfterAlteration = v.dataValueAfterAlteration.replace(/\n/g, '<br>')
            v.dataValueBeforeAlteration = v.dataValueBeforeAlteration.replace(/\n/g, '<br>')
            return v
          })
          this.reportSA = []
          this.reportSA['ReportSA'] = res
          return new act.ReportSASuccess(this.reportSA)
        }),
      )
    }),
  )
  @Effect()
  getReportWATDetail: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAT_DETAIL),
    map((action: act.ReportWATDetail) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWATDetail(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.GAMING_AREA = typeof v.GAMING_AREA !== 'object' ? v.GAMING_AREA : ''
            v.ACCOUNT_NUMBER = typeof v.ACCOUNT_NUMBER !== 'object' ? v.ACCOUNT_NUMBER : ''
            v.AMOUNT = typeof v.AMOUNT !== 'object' ? v.AMOUNT : 0
            v.TRANSACTION_TYPE = typeof v.TRANSACTION_TYPE !== 'object' ? v.TRANSACTION_TYPE : ''
            v.CASHIER = typeof v.CASHIER !== 'object' ? v.CASHIER : ''
            v.FIRST_NAME = typeof v.FIRST_NAME !== 'object' ? v.FIRST_NAME : ''
            v.LAST_NAME = typeof v.LAST_NAME !== 'object' ? v.LAST_NAME : ''
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''
            v.AMOUNT = this.currencyPipe.transform(v.AMOUNT, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportWATDetail = []
          this.reportWATDetail['ReportWATDetail'] = res
          return new act.ReportWATDetailSuccess(this.reportWATDetail)
        }),
      )
    }),
  )
  @Effect()
  getReportWATSummaryGaming: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAT_SUMMARY_GAMING),
    map((action: act.ReportWATSummaryGaming) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWATSummaryGaming(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.GAMING_AREA = typeof v.GAMING_AREA !== 'object' ? v.GAMING_AREA : ''
            v.WAT_IN = typeof v.WAT_IN !== 'object' ? v.WAT_IN : 0
            v.WAT_OUT = typeof v.WAT_OUT !== 'object' ? v.WAT_OUT : 0
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''

            v.WAT_IN = this.currencyPipe.transform(v.WAT_IN, 'USD', 'symbol', '1.2-2')
            v.WAT_OUT = this.currencyPipe.transform(v.WAT_OUT, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportWATSummaryGaming = []
          this.reportWATSummaryGaming['ReportWATSummaryGaming'] = res
          return new act.ReportWATSummaryGamingSuccess(this.reportWATSummaryGaming)
        }),
      )
    }),
  )
  @Effect()
  getReportWATSummaryOverall: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAT_SUMMARY_OVERALL),
    map((action: act.ReportWATSummaryOverall) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWATSummaryOverall(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.GAMING_AREA = typeof v.GAMING_AREA !== 'object' ? v.GAMING_AREA : ''
            v.WAT_IN = typeof v.WAT_IN !== 'object' ? v.WAT_IN : 0
            v.WAT_OUT = typeof v.WAT_OUT !== 'object' ? v.WAT_OUT : 0
            v.TRANSACTION_DATE = typeof v.TRANSACTION_DATE !== 'object' ? v.TRANSACTION_DATE : ''

            v.WAT_IN = this.currencyPipe.transform(v.WAT_IN, 'USD', 'symbol', '1.2-2')
            v.WAT_OUT = this.currencyPipe.transform(v.WAT_OUT, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportWATSummaryOverall = []
          this.reportWATSummaryOverall['ReportWATSummaryOverall'] = res
          return new act.ReportWATSummaryOverallSuccess(this.reportWATSummaryOverall)
        }),
      )
    }),
  )
  @Effect()
  getReportUserAccessLogs: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_USER_ACCESS_LOGS),
    map((action: act.ReportUserAccessLogs) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportUserAccessLogs(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.employeeTitle = typeof v.employeeTitle !== 'object' ? v.employeeTitle : ''
            v.acctCreationDateTime =
              typeof v.acctCreationDateTime !== 'object' ? v.acctCreationDateTime : ''
            v.lastLoginDateTime = typeof v.lastLoginDateTime !== 'object' ? v.lastLoginDateTime : ''
            v.lastPasswordChangeDateTime =
              typeof v.lastPasswordChangeDateTime !== 'object' ? v.lastPasswordChangeDateTime : ''
            v.accountStatusChangeDate =
              typeof v.accountStatusChangeDate !== 'object' ? v.accountStatusChangeDate : ''
            v.accountStatus = typeof v.accountStatus !== 'object' ? v.accountStatus : ''
            v.userGroup = typeof v.userGroup !== 'object' ? v.userGroup : ''

            v.name = `${v.firstName} ${v.lastName}`
            v.accountStatus = v.accountStatus == 'A' ? 'Active' : 'Deactivated'
            return v
          })
          this.reportUserAccessLogs = []
          this.reportUserAccessLogs['ReportUserAccessLogs'] = res
          return new act.ReportUserAccessLogsSuccess(this.reportUserAccessLogs)
        }),
      )
    }),
  )
  @Effect()
  getReportPlayerActivity: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_ACTIVITY),
    map((action: act.ReportPlayerActivity) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPlayerActivityReport(payload).pipe(
        map(res => {
          this.tabType = payload.payload.type
          if (this.tabType == 'SUMMARY') {
            res.data = [
              {
                CATEGORY: 'Total Beginning Balance',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalbeginningBalance.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalbeginningBalance.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalbeginningBalance.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalbeginningBalance.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalbeginningBalance.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalbeginningBalance.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalbeginningBalance.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalbeginningBalance.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalbeginningBalance.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalbeginningBalance.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalbeginningBalance.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalbeginningBalance.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Deposits',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalDeposits.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalDeposits.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalDeposits.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalDeposits.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalDeposits.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalDeposits.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalDeposits.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalDeposits.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalDeposits.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalDeposits.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalDeposits.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalDeposits.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Cash In',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalCashIn.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalCashIn.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalCashIn.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashIn.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalCashIn.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashIn.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashIn.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalCashIn.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashIn.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashIn.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalCashIn.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashIn.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Cash Out',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalCashlOut.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalCashlOut.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlOut.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlOut.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlOut.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlOut.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlOut.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalCashlOut.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlOut.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlOut.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalCashlOut.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalCashlOut.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Withdrawals',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalWithdrawals.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalWithdrawals.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalWithdrawals.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalWithdrawals.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalWithdrawals.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalWithdrawals.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalWithdrawals.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalWithdrawals.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalWithdrawals.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalWithdrawals.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalWithdrawals.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalWithdrawals.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Adjustments',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalAdjustments.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalAdjustments.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalAdjustments.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalAdjustments.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalAdjustments.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalAdjustments.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalAdjustments.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalAdjustments.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalAdjustments.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalAdjustments.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalAdjustments.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalAdjustments.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
              {
                CATEGORY: 'Total Ending Balance',
                CURRENT_DAY_TOTAL: isNaN(res.data[0].totalendingBalance.CURRENT_DAY_TOTAL)
                  ? res.data[0].totalendingBalance.CURRENT_DAY_TOTAL
                  : this.currencyPipe.transform(
                      res.data[0].totalendingBalance.CURRENT_DAY_TOTAL,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalendingBalance.MONTH_TO_DATE_TOTALS)
                  ? res.data[0].totalendingBalance.MONTH_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalendingBalance.MONTH_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalendingBalance.PREVIOUS_YEAR_TOTALS)
                  ? res.data[0].totalendingBalance.PREVIOUS_YEAR_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalendingBalance.PREVIOUS_YEAR_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
                YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalendingBalance.YEAR_TO_DATE_TOTALS)
                  ? res.data[0].totalendingBalance.YEAR_TO_DATE_TOTALS
                  : this.currencyPipe.transform(
                      res.data[0].totalendingBalance.YEAR_TO_DATE_TOTALS,
                      'USD',
                      'symbol',
                      '1.2-2',
                    ),
              },
            ]
          } else if (this.tabType == 'DETAILED') {
            res.data = res.data.map(v => {
              v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
              v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
              v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
              v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
              v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
              v.gamingDeviceId = typeof v.gamingDeviceId !== 'object' ? v.gamingDeviceId : ''
              v.transactionDateTime =
                typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''
              v.openingBalance = typeof v.openingBalance !== 'object' ? v.openingBalance : 0
              v.depositFromFundingSource =
                typeof v.depositFromFundingSource !== 'object' ? v.depositFromFundingSource : 0
              v.withdrawalsToGamingActivity =
                typeof v.withdrawalsToGamingActivity !== 'object'
                  ? v.withdrawalsToGamingActivity
                  : 0
              v.depositFromGamingActivity =
                typeof v.depositFromGamingActivity !== 'object' ? v.depositFromGamingActivity : 0
              v.withdrawalsToFundingSource =
                typeof v.withdrawalsToFundingSource !== 'object' ? v.withdrawalsToFundingSource : 0
              v.adjustments = typeof v.adjustments !== 'object' ? v.adjustments : 0
              v.closingBalance = typeof v.closingBalance !== 'object' ? v.closingBalance : 0

              v.openingBalance = this.currencyPipe.transform(
                v.openingBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.depositFromFundingSource = this.currencyPipe.transform(
                v.depositFromFundingSource,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.withdrawalsToGamingActivity = this.currencyPipe.transform(
                v.withdrawalsToGamingActivity,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.depositFromGamingActivity = this.currencyPipe.transform(
                v.depositFromGamingActivity,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.withdrawalsToFundingSource = this.currencyPipe.transform(
                v.withdrawalsToFundingSource,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.adjustments = this.currencyPipe.transform(v.adjustments, 'USD', 'symbol', '1.2-2')
              v.closingBalance = this.currencyPipe.transform(
                v.closingBalance,
                'USD',
                'symbol',
                '1.2-2',
              )

              v.gt_adjustment = this.currencyPipe.transform(
                v.grandTotal_adjustments,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_beg_bal = this.currencyPipe.transform(
                v.grandTotal_beginningBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_cin = this.currencyPipe.transform(
                v.grandTotal_withdrawalsToGamingActivity,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_cout = this.currencyPipe.transform(
                v.grandTotal_depositFromGamingActivity,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_deposits = this.currencyPipe.transform(
                v.grandTotal_depositFromFundingSource,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_end_bal = this.currencyPipe.transform(
                v.grandTotal_endingBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_withdrawals = this.currencyPipe.transform(
                v.grandTotal_withdrawalsToFundingSource,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })
          } else {
            res.data = res.data.map(v => {
              // if data retrieved is object, return to 0
              v.adjustments = typeof v.adjustments !== 'object' ? v.adjustments : 0
              v.beginningBalance = typeof v.beginningBalance !== 'object' ? v.beginningBalance : 0
              v.cashIn = typeof v.cashIn !== 'object' ? v.cashIn : 0
              v.cashOut = typeof v.cashOut !== 'object' ? v.cashOut : 0
              v.deposits = typeof v.deposits !== 'object' ? v.deposits : 0
              v.endingBalance = typeof v.endingBalance !== 'object' ? v.endingBalance : 0
              v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
              v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
              v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
              v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
              v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
              v.sourceType = typeof v.sourceType !== 'object' ? v.sourceType : ''
              v.transactionDateTime =
                typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''
              v.withdrawals = typeof v.withdrawals !== 'object' ? v.withdrawals : 0
              v.adjustments = this.currencyPipe.transform(v.adjustments, 'USD', 'symbol', '1.2-2')
              v.beginningBalance = this.currencyPipe.transform(
                v.beginningBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.cashIn = this.currencyPipe.transform(v.cashIn, 'USD', 'symbol', '1.2-2')
              v.cashOut = this.currencyPipe.transform(v.cashOut, 'USD', 'symbol', '1.2-2')
              v.deposits = this.currencyPipe.transform(v.deposits, 'USD', 'symbol', '1.2-2')
              v.endingBalance = this.currencyPipe.transform(
                v.endingBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.withdrawals = this.currencyPipe.transform(v.withdrawals, 'USD', 'symbol', '1.2-2')
              v.gt_adjustment = this.currencyPipe.transform(
                v.grandTotal_adjustments,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_beg_bal = this.currencyPipe.transform(
                v.grandTotal_beginningBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_cin = this.currencyPipe.transform(v.grandTotal_cashIn, 'USD', 'symbol', '1.2-2')
              v.gt_cout = this.currencyPipe.transform(
                v.grandTotal_cashOut,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_deposits = this.currencyPipe.transform(
                v.grandTotal_deposits,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_end_bal = this.currencyPipe.transform(
                v.grandTotal_endingBalance,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.gt_withdrawals = this.currencyPipe.transform(
                v.grandTotal_withdrawals,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })
          }
          this.multipleReportTabs.PlayerActivity = {}
          this.multipleReportTabs.PlayerActivity[this.tabType] = res
          return new act.ReportPlayerActivitySuccess(this.multipleReportTabs)
        }),
      )
    }),
  )
  @Effect()
  getReportEL: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_EL),
    map((action: act.ReportEL) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportEL(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.alterationUserId = typeof v.alterationUserId !== 'object' ? v.alterationUserId : ''
            v.dataAlterationType =
              typeof v.dataAlterationType !== 'object' ? v.dataAlterationType : ''
            v.dataAlterationLog = typeof v.dataAlterationLog !== 'object' ? v.dataAlterationLog : ''
            v.dataValueBeforeAlteration =
              typeof v.dataValueBeforeAlteration !== 'object' ? v.dataValueBeforeAlteration : ''
            v.dataValueAfterAlteration =
              typeof v.dataValueAfterAlteration !== 'object' ? v.dataValueAfterAlteration : ''
            v.logTimeStamp = typeof v.logTimeStamp !== 'object' ? v.logTimeStamp : ''
            v.alterationOwner = typeof v.alterationOwner !== 'object' ? v.alterationOwner : ''

            v.transactionAmount = this.currencyPipe.transform(
              v.transactionAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportEL = []
          this.reportEL['ReportEL'] = res
          return new act.ReportELSuccess(this.reportEL)
        }),
      )
    }),
  )
  @Effect()
  getPatronInfoReport: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PATRON_INFO),
    map((action: act.PatronInfoReport) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPatronInfoReport(payload).pipe(
        map(res => {
          let accountIds = []
          let accountIdsCount = []
          let count = 1
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.accountId = typeof v.accountId !== 'object' ? v.accountId : ''
            v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.transactionId = typeof v.transactionId !== 'object' ? v.transactionId : ''
            v.transactionDate = typeof v.transactionDate !== 'object' ? v.transactionDate : ''
            v.cashTransactionAmount =
              typeof v.cashTransactionAmount !== 'object' ? v.cashTransactionAmount : ''
            v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
            v.Client = typeof v.Client !== 'object' ? v.Client : ''
            v.clientId = typeof v.clientId !== 'object' ? v.clientId : ''
            v.currentBalance = typeof v.currentBalance !== 'object' ? v.currentBalance : ''
            v.transactionReason = typeof v.transactionReason !== 'object' ? v.transactionReason : ''

            v.cashTransactionAmount = this.currencyPipe.transform(
              v.cashTransactionAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.currentBalance = this.currencyPipe.transform(
              v.currentBalance,
              'USD',
              'symbol',
              '1.2-2',
            )

            if (accountIds.indexOf(v.accountId) == -1) {
              count = 1
              accountIds.push(v.accountId)
              v.show = true
            } else {
              accountIdsCount[accountIds.indexOf(v.accountId)] = ++count
              v.show = false
            }
            return v
          })
          res.accountIds = accountIds
          res.accountIdsCount = accountIdsCount
          this.reportPatronInfo = []
          this.reportPatronInfo['ReportPatronInfoReport'] = res
          return new act.PatronInfoReportSuccess(this.reportPatronInfo)
        }),
      )
    }),
  )
  @Effect()
  getTransactionSessionReport: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_TRANSACTION_SESSION),
    map((action: act.TransactionSessionReport) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getTransactionSessionReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.cashTransactionAmount = this.currencyPipe.transform(
              v.cashTransactionAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.currentBalance = this.currencyPipe.transform(
              v.currentBalance,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportTransactionSession = []
          this.reportTransactionSession['ReportTransactionSession'] = res
          return new act.PatronInfoReportSuccess(this.reportTransactionSession)
        }),
      )
    }),
  )
  @Effect()
  getReportWatInRecon: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAT_IN_RECON),
    map((action: act.ReportWatInRecon) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWatInRecon(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.deviceID = typeof v.deviceID !== 'object' ? v.deviceID : ''
            v.wat_in_device = typeof v.wat_in_device !== 'object' ? v.wat_in_device : 0
            v.wat_in_system = typeof v.wat_in_system !== 'object' ? v.wat_in_system : 0
            v.variance = typeof v.variance !== 'object' ? v.variance : 0
            v.variancePctg = typeof v.variancePctg !== 'object' ? v.variancePctg : 0

            v.wat_in_device = this.currencyPipe.transform(v.wat_in_device, 'USD', 'symbol', '1.2-2')
            v.wat_in_system = this.currencyPipe.transform(v.wat_in_system, 'USD', 'symbol', '1.2-2')
            v.variance = this.currencyPipe.transform(v.variance, 'USD', 'symbol', '1.2-2')
            v.variancePctg = this.percentPipe.transform(v.variancePctg, '1.2-5')
            v.total_wat_in_out_device = this.currencyPipe.transform(
              v.total_wat_in_out_device,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_wat_in_out_system = this.currencyPipe.transform(
              v.total_wat_in_out_system,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_variance = this.currencyPipe.transform(
              v.total_variance,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_variancePctg = this.percentPipe.transform(v.total_variancePctg, '1.2-5')
            return v
          })
          this.reportWatInRecon = []
          this.reportWatInRecon['ReportWatInRecon'] = res
          return new act.ReportWatInReconSuccess(this.reportWatInRecon)
        }),
      )
    }),
  )
  @Effect()
  getReportWatOutRecon: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_WAT_OUT_RECON),
    map((action: act.ReportWatOutRecon) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportWatOutRecon(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.deviceID = typeof v.deviceID !== 'object' ? v.deviceID : ''
            v.wat_in_device = typeof v.wat_in_device !== 'object' ? v.wat_in_device : 0
            v.wat_in_system = typeof v.wat_in_system !== 'object' ? v.wat_in_system : 0
            v.variance = typeof v.variance !== 'object' ? v.variance : 0
            v.variancePctg = typeof v.variancePctg !== 'object' ? v.variancePctg : 0

            v.wat_in_device = this.currencyPipe.transform(v.wat_in_device, 'USD', 'symbol', '1.2-2')
            v.wat_in_system = this.currencyPipe.transform(v.wat_in_system, 'USD', 'symbol', '1.2-2')
            v.variance = this.currencyPipe.transform(v.variance, 'USD', 'symbol', '1.2-2')
            v.variancePctg = this.percentPipe.transform(v.variancePctg, '1.2-5')
            v.total_wat_in_out_device = this.currencyPipe.transform(
              v.total_wat_in_out_device,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_wat_in_out_system = this.currencyPipe.transform(
              v.total_wat_in_out_system,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_variance = this.currencyPipe.transform(
              v.total_variance,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.total_variancePctg = this.percentPipe.transform(v.total_variancePctg, '1.2-5')
            return v
          })
          this.reportWatOutRecon = []
          this.reportWatOutRecon['ReportWatOutRecon'] = res
          return new act.ReportWatOutReconSuccess(this.reportWatOutRecon)
        }),
      )
    }),
  )
  @Effect()
  getReportFailedTransactions: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_FAILED_TRANSACTIONS),
    map((action: act.ReportFailedTransactions) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportFailedTransactions(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
            v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
            v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.failedTransReason = typeof v.failedTransReason !== 'object' ? v.failedTransReason : ''
            v.failedTransDesc = typeof v.failedTransDesc !== 'object' ? v.failedTransDesc : ''
            v.attemptCount = typeof v.attemptCount !== 'object' ? v.attemptCount : ''
            v.transactionDateTime =
              typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''
            v.SMIB_ID = typeof v.SMIB_ID !== 'object' ? v.SMIB_ID : ''
            v.failedTransAmount = typeof v.failedTransAmount !== 'object' ? v.failedTransAmount : 0
            v.deviceId = typeof v.deviceId !== 'object' ? v.deviceId : ''
            v.total = res.total
            v.failedTransAmount = this.currencyPipe.transform(
              v.failedTransAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.grandTotal_Amount = this.currencyPipe.transform(
              v.grandTotal_Amount,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportFailedTransactions = []
          this.reportFailedTransactions['ReportFailedTransactions'] = res
          return new act.ReportFailedTransactionsSuccess(this.reportFailedTransactions)
        }),
      )
    }),
  )
  @Effect()
  getReportSystemErrosandExceptions: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_SYSTEM_ERRORS_EXCEPTIONS),
    map((action: act.ReportSystemErrorsandExceptions) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportSystemErrorsandExceptions(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
            v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
            v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.deviceId = typeof v.deviceId !== 'object' ? v.deviceId : ''
            v.transactionId = typeof v.transactionId !== 'object' ? v.transactionId : ''
            v.errorType = typeof v.errorType !== 'object' ? v.errorType : ''
            v.transactionDateTime =
              typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''
            v.SMIB_ID = typeof v.SMIB_ID !== 'object' ? v.SMIB_ID : ''
            v.errorDesc = typeof v.errorDesc !== 'object' ? v.errorDesc : 0
            v.attemptCount = typeof v.attemptCount !== 'object' ? v.attemptCount : ''

            return v
          })
          this.reportSystemErrorsandExceptions = []
          this.reportSystemErrorsandExceptions['ReportSystemErrorsandExceptions'] = res
          return new act.ReportSystemErrorsandExceptionsSuccess(
            this.reportSystemErrorsandExceptions,
          )
        }),
      )
    }),
  )
  @Effect()
  getReportCME: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CME),
    map((action: act.ReportCashlessMonthEndRecon) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportCME(payload).pipe(
        map(res => {
          res.data = [
            {
              CATEGORY: 'Previous Month Liability Balance',
              MONTH_TO_DATE_TOTALS: isNaN(
                res.data[0].prevMonthLiabilityBalance.MONTH_TO_DATE_TOTALS,
              )
                ? res.data[0].prevMonthLiabilityBalance.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].prevMonthLiabilityBalance.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].prevMonthLiabilityBalance.YEAR_TO_DATE_TOTALS)
                ? res.data[0].prevMonthLiabilityBalance.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].prevMonthLiabilityBalance.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(
                res.data[0].prevMonthLiabilityBalance.PREVIOUS_YEAR_TOTALS,
              )
                ? res.data[0].prevMonthLiabilityBalance.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].prevMonthLiabilityBalance.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Total Cashless Wagered In',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessIn.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessIn.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Total Cashless Wagered Out',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessOut.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessOut.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlessOut.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].totalCashlessOut.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessIn.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Total Liability to the Casino ',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCasinoLiability.MONTH_TO_DATE_TOTALS)
                ? res.data[0].totalCasinoLiability.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCasinoLiability.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCasinoLiability.YEAR_TO_DATE_TOTALS)
                ? res.data[0].totalCasinoLiability.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCasinoLiability.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCasinoLiability.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].totalCasinoLiability.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCasinoLiability.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Total Liability to the Patrons',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalPatronLiability.MONTH_TO_DATE_TOTALS)
                ? res.data[0].totalPatronLiability.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalPatronLiability.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalPatronLiability.YEAR_TO_DATE_TOTALS)
                ? res.data[0].totalPatronLiability.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalPatronLiability.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalPatronLiability.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].totalPatronLiability.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalPatronLiability.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Grand Total Cashless Transactions',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessTransaction.MONTH_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessTransaction.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessTransaction.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].totalCashlessTransaction.YEAR_TO_DATE_TOTALS)
                ? res.data[0].totalCashlessTransaction.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessTransaction.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].totalCashlessTransaction.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].totalCashlessTransaction.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].totalCashlessTransaction.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
            {
              CATEGORY: 'Grand Total Net Cashless Liability',
              MONTH_TO_DATE_TOTALS: isNaN(res.data[0].netCashlessLiability.MONTH_TO_DATE_TOTALS)
                ? res.data[0].netCashlessLiability.MONTH_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].netCashlessLiability.MONTH_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              YEAR_TO_DATE_TOTALS: isNaN(res.data[0].netCashlessLiability.YEAR_TO_DATE_TOTALS)
                ? res.data[0].netCashlessLiability.YEAR_TO_DATE_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].netCashlessLiability.YEAR_TO_DATE_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
              PREVIOUS_YEAR_TOTALS: isNaN(res.data[0].netCashlessLiability.PREVIOUS_YEAR_TOTALS)
                ? res.data[0].netCashlessLiability.PREVIOUS_YEAR_TOTALS
                : this.currencyPipe.transform(
                    res.data[0].netCashlessLiability.PREVIOUS_YEAR_TOTALS,
                    'USD',
                    'symbol',
                    '1.2-2',
                  ),
            },
          ]
          this.reportCME = []
          this.reportCME['ReportCashlessMonthEndRecon'] = res
          return new act.ReportCashlessMonthEndReconSuccess(this.reportCME)
        }),
      )
    }),
  )
  @Effect()
  getReportAuditTrail: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_ATR),
    map((action: act.ReportAuditTrail) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getReportAuditTrail(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
            v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
            v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
            v.deviceId = typeof v.deviceId !== 'object' ? v.deviceId : ''
            v.cashierId = typeof v.cashierId !== 'object' ? v.cashierId : ''
            v.sourceType = typeof v.sourceType !== 'object' ? v.sourceType : ''
            v.adjustmentDateTime =
              typeof v.adjustmentDateTime !== 'object' ? v.adjustmentDateTime : ''
            v.transactionDateTime =
              typeof v.transactionDateTime !== 'object' ? v.transactionDateTime : ''
            v.failedTransAmount = typeof v.failedTransAmount !== 'object' ? v.failedTransAmount : 0
            v.deviceId = typeof v.deviceId !== 'object' ? v.deviceId : ''
            // v.total_prevTotal_Amount = res.prevTotal_Amount
            // v.total_adjustedTotal_Amount = res.adjustedTotal_Amount
            // v.total_adjustedTotal_Amount = res.finalTotal_Amount
            v.finalTransactionValue = this.currencyPipe.transform(
              v.finalTransactionValue,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.adjustedTransactionValue = this.currencyPipe.transform(
              v.adjustedTransactionValue,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.prevTransactionValue = this.currencyPipe.transform(
              v.prevTransactionValue,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.prevTotal_Amount = this.currencyPipe.transform(
              v.prevTotal_Amount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.adjustedTotal_Amount = this.currencyPipe.transform(
              v.adjustedTotal_Amount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.finalTotal_Amount = this.currencyPipe.transform(
              v.finalTotal_Amount,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportAuditTrail = []
          this.reportAuditTrail['ReportAuditTrail'] = res
          return new act.ReportAuditTrailSuccess(this.reportAuditTrail)
        }),
      )
    }),
  )
  @Effect()
  getReportCashierTransaction: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CASHIER_TRANSACTION),
    map((action: act.ReportCashierTransactions) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getCashierTransactionReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.propertyId = typeof v.propertyId !== 'object' ? v.propertyId : ''
            v.propertyName = typeof v.propertyName !== 'object' ? v.propertyName : ''
            v.cashierId = typeof v.cashierId !== 'object' ? v.cashierId : 0
            v.cashierName = typeof v.cashierName !== 'object' ? v.cashierName : ''
            v.playerCardNumber = typeof v.playerCardNumber !== 'object' ? v.playerCardNumber : ''
            v.firstName = typeof v.firstName !== 'object' ? v.firstName : ''
            v.lastName = typeof v.lastName !== 'object' ? v.lastName : ''
            v.transactionDetails =
              typeof v.transactionDetails !== 'object' ? v.transactionDetails : ''
            v.transactionType = typeof v.transactionType !== 'object' ? v.transactionType : ''
            v.debit = this.currencyPipe.transform(v.debit, 'USD', 'symbol', '1.2-2')
            v.credit = this.currencyPipe.transform(v.credit, 'USD', 'symbol', '1.2-2')
            v.transactionTotal = this.currencyPipe.transform(
              v.transactionTotal,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.debit_Total = this.currencyPipe.transform(v.debit_Total, 'USD', 'symbol', '1.2-2')
            v.credit_Total = this.currencyPipe.transform(v.credit_Total, 'USD', 'symbol', '1.2-2')
            v.transaction_Total = this.currencyPipe.transform(
              v.transaction_Total,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportCTR = []
          this.reportCTR['ReportCashierTransaction'] = res
          return new act.ReportCashierTransactionsSuccess(this.reportCTR)
        }),
      )
    }),
  )
  @Effect()
  getLimitActivityReport: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_LIMIT_ACTIVITY),
    map((action: act.LimitActivityReport) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getLimitActivityReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.playerId = typeof v.playerId !== 'object' ? v.playerId : ''
            v.prevDaily = this.currencyPipe.transform(v.prevDaily, 'USD', 'symbol', '1.2-2')
            v.newDaily = this.currencyPipe.transform(v.newDaily, 'USD', 'symbol', '1.2-2')
            v.prevMonthly = this.currencyPipe.transform(v.prevMonthly, 'USD', 'symbol', '1.2-2')
            v.newMonthly = this.currencyPipe.transform(v.newMonthly, 'USD', 'symbol', '1.2-2')
            return v
          })
          this.reportLimitActivity = []
          this.reportLimitActivity['ReportLimitActivity'] = res
          return new act.LimitActivityReportSuccess(this.reportLimitActivity)
        }),
      )
    }),
  )
  @Effect()
  getPlayerStatusChangeReport: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_PLAYER_STATUS_CHANGE),
    map((action: act.PlayerStatusChangeReport) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getPlayerStatusReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.playerId = typeof v.playerId !== 'object' ? v.playerId : ''
            v.prevStatus = typeof v.prevStatus !== 'object' ? v.prevStatus : ''
            v.newStatus = typeof v.newStatus !== 'object' ? v.newStatus : ''
            v.changeType = typeof v.changeType !== 'object' ? v.changeType : ''
            v.timeStamp = typeof v.timeStamp !== 'object' ? v.timeStamp : ''
            v.updateBy = typeof v.updateBy !== 'object' ? v.updateBy : ''
            return v
          })
          this.playerStatusChangeReport = []
          this.playerStatusChangeReport['ReportPlayerStatusChangeReport'] = res
          return new act.PlayerStatusChangeReportSuccess(this.playerStatusChangeReport)
        }),
      )
    }),
  )
  @Effect()
  getReportCashierSummary: Observable<any> = this.actions.pipe(
    ofType(act.REPORT_CASHIER_SUMMARY),
    map((action: act.ReportCashierSummary) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.reportService.getCashierSummaryReport(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            // if data retrieved is object, return to 0
            v.cashierId = typeof v.cashierId !== 'object' ? v.cashierId : ''
            v.cashierName = typeof v.cashierName !== 'object' ? v.cashierName : ''
            v.cashier_transactionTotal = this.currencyPipe.transform(
              v.cashier_transactionTotal,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.cashier_totalCredit = this.currencyPipe.transform(
              v.cashier_totalCredit,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.cashier_totalDebit = this.currencyPipe.transform(
              v.cashier_totalDebit,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.reportCashierSummaryReport = []
          this.reportCashierSummaryReport['ReportCashierSummary'] = res
          return new act.ReportCashierSummarySuccess(this.reportCashierSummaryReport)
        }),
      )
    }),
  )
}
