<div class="breadcrumbs">
  <div class="path">
    <a routerLink="{{breadcrumbs[0].url}}" [class.current]="breadcrumbs[0]==false">{{ breadcrumbs[0].title }}</a>
    <ng-container *ngFor="let item of breadcrumbs; let last = last;">
      <span *ngIf="breadcrumbs.length - 1 > 1 && !last">
        <span> / </span>
        <a href routerLink="{{item.url}}">
          <span>{{ item.title }}</span>
        </a>
      </span>
      <span *ngIf="breadcrumbs.length > 1 && last && item.title">
        <span> / </span>
        <span class="current fw-500">{{ item.title }}</span>
      </span>
    </ng-container>
  </div>
</div>