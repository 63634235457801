import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
  ActionReducer,
  MetaReducer,
} from '@ngrx/store'
import { environment } from 'src/environments/environment'
import * as fromRouter from '@ngrx/router-store'
import * as fromSettings from './settings/reducers'
import * as fromUser from './user/reducers'
import * as fromUserManagement from './userManagement/reducers'
import * as fromPlayer from './player/reducers'
import * as fromReport from './report/reducers'
export const reducers: ActionReducerMap<any> = {
  router: fromRouter.routerReducer,
  settings: fromSettings.reducer,
  user: fromUser.reducer,
  userManagement: fromUserManagement.reducer,
  player: fromPlayer.reducer,
  report: fromReport.reducer,
}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any): any => {
    const result = reducer(state, action)
    // console.groupCollapsed(action.type)
    // console.log('prev state', state)
    // console.log('action', action)
    // console.log('next state', result)
    // console.groupEnd()
    return result
  }
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : []

export const getSettingsState = createFeatureSelector<any>('settings')
export const getSettings = createSelector(getSettingsState, fromSettings.getSettings)
export const getUserState = createFeatureSelector<any>('user')
export const getUser = createSelector(getUserState, fromUser.getUser)
export const loadCurrentAccount = createSelector(getUserState, fromUser.loadCurrentAccount)
export const loginSettings = createSelector(getUserState, fromUser.loginSettings)

export const getPlayerState = createFeatureSelector<any>('player')
export const getPlayerList = createSelector(getPlayerState, fromPlayer.getPlayerList)
export const getPlayerDetails = createSelector(getPlayerState, fromPlayer.getPlayerDetails)
export const getPlayerLimits = createSelector(getPlayerState, fromPlayer.getPlayerLimits)
export const getPlayerFeeInfo = createSelector(getPlayerState, fromPlayer.getPlayerFeeInfo)
export const getPlayerMarkerInfo = createSelector(getPlayerState, fromPlayer.getPlayerMarkerInfo)
export const getPlayerActList = createSelector(getPlayerState, fromPlayer.getPlayerActList)
export const getLimitActList = createSelector(getPlayerState, fromPlayer.getLimitActList)
export const getFeeActList = createSelector(getPlayerState, fromPlayer.getFeeActList)
export const getStatement = createSelector(getPlayerState, fromPlayer.getStatement)
export const getPaymentActList = createSelector(getPlayerState, fromPlayer.getPaymentActList)
export const makePaymentResult = createSelector(getPlayerState, fromPlayer.makePaymentResult)
export const cashOutResult = createSelector(getPlayerState, fromPlayer.cashOutResult)
export const internalAdjustmentResult = createSelector(
  getPlayerState,
  fromPlayer.internalAdjustmentResult,
)
export const makeAdjustmentResult = createSelector(getPlayerState, fromPlayer.makeAdjustmentResult)
export const generateAgreement = createSelector(getPlayerState, fromPlayer.generateAgreement)
export const previewAgreement = createSelector(getPlayerState, fromPlayer.previewAgreement)
export const generateStatement = createSelector(getPlayerState, fromPlayer.generateStatement)
export const getCreditFactor = createSelector(getPlayerState, fromPlayer.getCreditFactor)

export const getUserManagementState = createFeatureSelector<any>('userManagement')
export const getUserList = createSelector(getUserManagementState, fromUserManagement.getUserList)
export const getEventLogList = createSelector(
  getUserManagementState,
  fromUserManagement.getEventLogList,
)
export const getRoleList = createSelector(getUserManagementState, fromUserManagement.getRoleList)
export const getGroupList = createSelector(getUserManagementState, fromUserManagement.getGroupList)
export const getModuleList = createSelector(
  getUserManagementState,
  fromUserManagement.getModuleList,
)
export const opsResetPwdResult = createSelector(
  getUserManagementState,
  fromUserManagement.opsResetPwdResult,
)
export const addUserResult = createSelector(
  getUserManagementState,
  fromUserManagement.addUserResult,
)

export const getReportState = createFeatureSelector<any>('report')
export const CMSDetails = createSelector(getReportState, fromReport.CMSDetails)
export const getReportWageringBalanceSummaryOverall = createSelector(
  getReportState,
  fromReport.getReportWageringBalanceSummaryOverall,
)
export const getReportWageringDetailedReport = createSelector(
  getReportState,
  fromReport.getReportWageringDetailedReport,
)
export const getReportWageringBalanceSummaryAccount = createSelector(
  getReportState,
  fromReport.getReportWageringBalanceSummaryAccount,
)
export const getReportPlayerAccountSummary = createSelector(
  getReportState,
  fromReport.getReportPlayerAccountSummary,
)
export const getReportPlayerAccountDetailed = createSelector(
  getReportState,
  fromReport.getReportPlayerAccountDetailed,
)
export const getPlayerActivityReport = createSelector(
  getReportState,
  fromReport.getPlayerActivityReport,
)
export const getReportLiability = createSelector(getReportState, fromReport.getReportLiability)
export const getCashierTransactionReport = createSelector(
  getReportState,
  fromReport.getCashierTransactionReport,
)
export const getLimitActivityReport = createSelector(
  getReportState,
  fromReport.getLimitActivityReport,
)
export const getReportAuditTrail = createSelector(getReportState, fromReport.getReportAuditTrail)
export const getReportLiabilityNew = createSelector(
  getReportState,
  fromReport.getReportLiabilityNew,
)
export const getReportCSD = createSelector(getReportState, fromReport.getReportCSD)
export const getPlayerStatusChangeReport = createSelector(
  getReportState,
  fromReport.getPlayerStatusReport,
)
export const getCashierSummaryReport = createSelector(getReportState, fromReport.getReportCSD)
export const getReportSA = createSelector(getReportState, fromReport.getReportSA)
export const getReportWATDetail = createSelector(getReportState, fromReport.getReportWATDetail)
export const getReportWATSummaryGaming = createSelector(
  getReportState,
  fromReport.getReportWATSummaryGaming,
)
export const getReportWATSummaryOverall = createSelector(
  getReportState,
  fromReport.getReportWATSummaryOverall,
)
export const getReportUserAccessLogs = createSelector(
  getReportState,
  fromReport.getReportUserAccessLogs,
)
export const getSystemErrorsandExceptions = createSelector(
  getReportState,
  fromReport.getReportSystemErrorsandExceptions,
)
export const getReportEL = createSelector(getReportState, fromReport.getReportEL)
export const getReportCME = createSelector(getReportState, fromReport.getReportCME)
export const getReportWatInRecon = createSelector(getReportState, fromReport.getReportWatInRecon)
export const getReportWatOutRecon = createSelector(getReportState, fromReport.getReportWatOutRecon)
export const getFailedTransactions = createSelector(
  getReportState,
  fromReport.getReportFailedTransactions,
)
export const getPatronInfoReport = createSelector(getReportState, fromReport.getPatronInfoReport)
export const getTransactionSessionReport = createSelector(
  getReportState,
  fromReport.getTransactionSessionReport,
)
export const getPatronNotifHistory = createSelector(
  getPlayerState,
  fromPlayer.getPatronNotifHistory,
)
