import { Component, OnInit, Input } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { filter } from 'rxjs/operators'
import * as _ from 'lodash'
import { select, Store } from '@ngrx/store'
import { MenuService } from 'src/app/services/menu'
import * as SettingsActions from 'src/app/store/settings/actions'
import * as Reducers from 'src/app/store/reducers'
import { NzNotificationService } from 'ng-zorro-antd'
import * as UserActions from 'src/app/store/user/actions'
import session from 'store'
import { Permission } from 'src/app/services/permission/index'
import { LocalService } from 'src/app/services/local.service'
import { MiscFunctions } from 'src/app/misc'

@Component({
  selector: 'cui-menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.scss'],
})
export class MenuLeftComponent implements OnInit {
  menuColor: String
  isMenuShadow: Boolean
  isMenuUnfixed: Boolean
  isSidebarOpen: Boolean
  isMobileView: Boolean
  leftMenuWidth: Number
  isMenuCollapsed: Boolean
  isMobileMenuOpen: Boolean
  logo: String
  menuData: any[]
  menuDataActivated: any[]
  role: String
  logoutConfirmationModal = false
  settingsSelected: boolean = false

  constructor(
    private menuService: MenuService,
    private store: Store<any>,
    private router: Router,
    private notification: NzNotificationService,
    public permission: Permission,
    private localService: LocalService,
    private misc: MiscFunctions,
  ) {}

  ngOnInit() {
    // this.permission.idleTimeOut(this.authSettings.SessionTimeout)
    const settings = this.permission.loginSettings()
    // setTimeout(() => {
    //   if (settings) {
    //     this.permission.idleTimeOut(settings['SESSION_TIMEOUT'])
    //   }
    // }, 1000)

    this.store.pipe(select(Reducers.getUser)).subscribe(state => {
      this.role = state.role
    })
    this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
    this.store.pipe(select(Reducers.getSettings)).subscribe(state => {
      this.menuColor = state.menuColor
      this.isMenuShadow = state.isMenuShadow
      this.isMenuUnfixed = state.isMenuUnfixed
      this.isSidebarOpen = state.isSidebarOpen
      this.isMobileView = state.isMobileView
      this.leftMenuWidth = state.leftMenuWidth
      this.isMenuCollapsed = state.isMenuCollapsed
      this.isMobileMenuOpen = state.isMobileMenuOpen
      this.logo = state.logo
    })
    var element = document.getElementById('top-searchbar')
    if (this.isMenuCollapsed) {
      element.classList.add('collapsed')
    } else {
      element.classList.remove('collapsed')
    }
    this.settingsSelected = this.router.url == '/settings'

    // console.log(this.permission.getMenuAccess())
    this.activateMenu(this.router.url)
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.activateMenu(event.url ? event.url : null)
      })
  }

  showSearchModal() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isLiveSearchVisible: true,
      }),
    )
  }

  activateMenu(url: any, menuData = this.menuData) {
    menuData = JSON.parse(JSON.stringify(menuData))
    const pathWithSelection = this.getPath({ url: url }, menuData, (entry: any) => entry, 'url')
    if (pathWithSelection) {
      pathWithSelection.pop().selected = true
      _.each(pathWithSelection, (parent: any) => (parent.open = true))
    }
    this.menuDataActivated = menuData.slice()
  }

  getPath(
    element: any,
    source: any,
    property: any,
    keyProperty = 'key',
    childrenProperty = 'children',
    path = [],
  ) {
    let found = false
    const getElementChildren = (value: any) => _.get(value, childrenProperty)
    const getElementKey = (value: any) => _.get(value, keyProperty)
    const key = getElementKey(element)
    return (
      _.some(source, (e: any) => {
        if (getElementKey(e) === key) {
          path.push(e)
          return true
        } else {
          return (found = this.getPath(
            element,
            getElementChildren(e),
            property,
            keyProperty,
            childrenProperty,
            path.concat(e),
          ))
        }
      }) &&
      (found || _.map(path, property))
    )
  }

  toggleSettings() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isSidebarOpen: !this.isSidebarOpen,
      }),
    )
  }

  setNav(type?) {}

  onCollapse(value: any) {
    var element = document.getElementById('top-searchbar')
    if (value) {
      element.classList.add('collapsed')
    } else {
      element.classList.remove('collapsed')
    }
    if (this.isMobileMenuOpen) return this.toggleMobileMenu()
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMenuCollapsed: value,
      }),
    )
  }
  toggleMobileMenu() {
    this.store.dispatch(
      new SettingsActions.SetStateAction({
        isMobileMenuOpen: !this.isMobileMenuOpen,
      }),
    )
  }

  logout() {
    this.store.dispatch(new UserActions.Logout())
    // this.localService.clearToken()
  }

  showConfirmation() {
    this.logoutConfirmationModal = true
  }

  redirect() {
    this.router.navigate([this.misc.landingPage])
  }
}
