<div class="footer">
  <div class="footerInner">
    <a [href]="'/#' + misc.landingPage" target="_blank" rel="noopener noreferrer" class="logo">
      {{metadata.title}}
    </a>
    <br />
    <p class="mb-0">
      <span class="text-kit">Copyright © {{currentYear}} {{metadata.title}}</span> |
      <a [href]="metadata.footer.privacy" target="_blank" rel="noopener noreferrer" class="text-primary">Privacy Policy</a> | {{version}}
    </p>
  </div>
</div>