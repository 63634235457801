export const getBreadCrumbData: any[] = [
  {
    title: 'Dashboard',
    key: 'dashboard',
    url: '/dashboard',
    children: [
      {
        title: 'CMS Details',
        key: 'cms-details',
        url: '/dashboard/dashboard-table',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Players',
    key: 'players',
    url: '/customer-management/customers',
    children: [
      {
        title: 'Player Details',
        key: 'player-details',
        url: '/customer-management/customer-details/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Users',
    key: 'users',
    url: '/users',
    children: [
      {
        title: 'Activity History',
        key: 'report-table',
        url: '/view-user/activity-history/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Reports',
    key: 'reports',
    url: '/reports',
    children: [
      {
        title: 'Report Details',
        key: 'report-table',
        url: '/reports/report-table/',
        hasWildCard: true,
      },
    ],
  },
  {
    title: 'Properties Management',
    key: 'propertiesManagement',
    url: '/properties-management',
  },
  {
    title: 'Notifications',
    key: 'notifications',
    url: '/notifications',
  },
  {
    title: 'Settings',
    key: 'settings',
    url: '/settings',
  },
  {
    title: 'Change Password',
    url: '/change-password',
  },
  {
    title: 'Profile',
    url: '/profile',
  },
  {
    title: 'Settlement',
    key: 'settlement',
    url: '/settlement',
    children: [
      {
        title: 'Registration',
        key: 'registration',
        url: '/settlement/registration',
      },
    ],
  },
]
