export const getMenuData: any[] = [
  {
    category: true,
    title: 'Dashboard',
  },
  {
    title: 'Dashboard',
    key: 'dashboard',
    icon: '/assets/icons/icon-dashboard.svg',
    url: '/dashboard',
    // roles: ['admin'], // set user roles with access to this route
  },
  {
    title: 'Report',
    key: 'report',
    icon: '/assets/icons/icon-reports.svg',
    url: '/reports',
  },
  {
    title: 'Players',
    key: 'players',
    icon: 'assets/icons/icon-users.svg',
    url: '/customer-management/customers',
  },

  {
    title: 'Users',
    key: 'user_management',
    icon: '/assets/icons/icon-users.svg',
    url: '/users',
  },
  {
    title: 'Settings',
    key: 'settings',
    icon: '/assets/icons/icon-settings.svg',
    url: '/settings',
    position: 'bottom',
  },

  // {
  //   title: 'Properties Management',
  //   key: 'propertiesManagement',
  //   icon: 'fe fe-users',
  //   url: '/properties-management',
  // },
  // {
  //   title: 'Notifications',
  //   key: 'notifications',
  //   icon: 'fe fe-bell',
  //   url: '/notifications',
  // },
]
