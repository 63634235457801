import { state } from '@angular/animations'
import * as act from './actions'

export const initialState: object = {}

export function reducer(state = initialState, action: act.Actions): object {
  switch (action.type) {
    case act.RESET:
      return initialState //Always return the initial state
    case act.CMS_DETAILS:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_OVERALL:
    case act.REPORT_WAGERING_DETAILED_REPORT:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT:
    case act.REPORT_PLAYER_ACCOUNT_SUMMARY:
    case act.REPORT_PLAYER_ACCOUNT_DETAILED:
    case act.REPORT_PLAYER_ACTIVITY:
    case act.REPORT_LIABILITY:
    case act.REPORT_LIABILITY_NEW:
    case act.REPORT_CSD:
    case act.REPORT_SA:
    case act.REPORT_WAT_DETAIL:
    case act.REPORT_WAT_SUMMARY_GAMING:
    case act.REPORT_WAT_SUMMARY_OVERALL:
    case act.REPORT_USER_ACCESS_LOGS:
    case act.REPORT_EL:
    case act.REPORT_WAT_IN_RECON:
    case act.REPORT_WAT_OUT_RECON:
    case act.REPORT_FAILED_TRANSACTIONS:
    case act.REPORT_SYSTEM_ERRORS_EXCEPTIONS:
    case act.REPORT_ATR:
    case act.REPORT_CME:
    case act.REPORT_CASHIER_TRANSACTION:
    case act.REPORT_CASHIER_SUMMARY:
    case act.REPORT_LIMIT_ACTIVITY:
    case act.REPORT_PLAYER_STATUS_CHANGE:
    case act.REPORT_PATRON_INFO:
      return {
        ...state,
        loading: true,
      }
    case act.CMS_DETAILS_SUCCESSFUL:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_SUCCESSFUL:
    case act.REPORT_WAGERING_DETAILED_REPORT_SUCCESSFUL:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_SUCCESSFUL:
    case act.REPORT_PLAYER_ACCOUNT_SUMMARY_SUCCESSFUL:
    case act.REPORT_PLAYER_ACCOUNT_DETAILED_SUCCESSFUL:
    case act.REPORT_LIABILITY_SUCCESSFUL:
    case act.REPORT_PLAYER_ACTIVITY_SUCCESSFUL:
    case act.REPORT_LIABILITY_NEW_SUCCESSFUL:
    case act.REPORT_CSD_SUCCESSFUL:
    case act.REPORT_SA_SUCCESSFUL:
    case act.REPORT_WAT_DETAIL_SUCCESSFUL:
    case act.REPORT_WAT_SUMMARY_GAMING_SUCCESSFUL:
    case act.REPORT_WAT_SUMMARY_OVERALL_SUCCESSFUL:
    case act.REPORT_USER_ACCESS_LOGS_SUCCESSFUL:
    case act.REPORT_EL_SUCCESSFUL:
    case act.REPORT_WAT_IN_RECON_SUCCESSFUL:
    case act.REPORT_WAT_OUT_RECON_SUCCESSFUL:
    case act.REPORT_FAILED_TRANSACTIONS_SUCCESSFUL:
    case act.REPORT_SYSTEM_ERRORS_EXCEPTIONS_SUCCESSFUL:
    case act.REPORT_ATR_SUCCESSFUL:
    case act.REPORT_CME_SUCCESSFUL:
    case act.REPORT_CASHIER_TRANSACTION_SUCCESSFUL:
    case act.REPORT_CASHIER_SUMMARY_SUCCESSFUL:
    case act.REPORT_LIMIT_ACTIVITY_SUCCESSFUL:
    case act.REPORT_PLAYER_STATUS_CHANGE_SUCCESSFUL:
    case act.REPORT_PATRON_INFO_SUCCESSFUL:
      return {
        ...state,
        ...action.data,
        loading: false,
        authorized: true,
      }
    case act.CMS_DETAILS_UNSUCCESSFUL:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_UNSUCCESSFUL:
    case act.REPORT_WAGERING_DETAILED_REPORT_UNSUCCESSFUL:
    case act.REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_UNSUCCESSFUL:
    case act.REPORT_PLAYER_ACCOUNT_SUMMARY_UNSUCCESSFUL:
    case act.REPORT_PLAYER_ACCOUNT_DETAILED_UNSUCCESSFUL:
    case act.REPORT_LIABILITY_UNSUCCESSFUL:
    case act.REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_LIABILITY_NEW_UNSUCCESSFUL:
    case act.REPORT_CSD_UNSUCCESSFUL:
    case act.REPORT_SA_UNSUCCESSFUL:
    case act.REPORT_WAT_DETAIL_UNSUCCESSFUL:
    case act.REPORT_WAT_SUMMARY_GAMING_UNSUCCESSFUL:
    case act.REPORT_WAT_SUMMARY_OVERALL_UNSUCCESSFUL:
    case act.REPORT_USER_ACCESS_LOGS_UNSUCCESSFUL:
    case act.REPORT_EL_UNSUCCESSFUL:
    case act.REPORT_WAT_IN_RECON_UNSUCCESSFUL:
    case act.REPORT_WAT_OUT_RECON_UNSUCCESSFUL:
    case act.REPORT_FAILED_TRANSACTIONS_UNSUCCESSFUL:
    case act.REPORT_SYSTEM_ERRORS_EXCEPTIONS_UNSUCCESSFUL:
    case act.REPORT_ATR_UNSUCCESSFUL:
    case act.REPORT_CME_UNSUCCESSFUL:
    case act.REPORT_CASHIER_TRANSACTION_UNSUCCESSFUL:
    case act.REPORT_CASHIER_SUMMARY_UNSUCCESSFUL:
    case act.REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL:
    case act.REPORT_PLAYER_STATUS_CHANGE_UNSUCCESSFUL:
    case act.REPORT_PATRON_INFO_UNSUCCESSFUL:
      return {
        ...state,
        loading: false,
        authorized: false,
      }
    default:
      return state
  }
}

export const CMSDetails = (state: any) => state
export const getReportWageringBalanceSummaryOverall = (state: any) => state
export const getReportWageringDetailedReport = (state: any) => state
export const getReportWageringBalanceSummaryAccount = (state: any) => state
export const getReportPlayerAccountSummary = (state: any) => state
export const getReportPlayerAccountDetailed = (state: any) => state
export const getReportLiability = (state: any) => state
export const getPlayerActivityReport = (state: any) => state
export const getReportLiabilityNew = (state: any) => state
export const getReportCSD = (state: any) => state
export const getReportSA = (state: any) => state
export const getReportWATDetail = (state: any) => state
export const getReportWATSummaryGaming = (state: any) => state
export const getReportWATSummaryOverall = (state: any) => state
export const getReportUserAccessLogs = (state: any) => state
export const getReportEL = (state: any) => state
export const getReportWatInRecon = (state: any) => state
export const getReportWatOutRecon = (state: any) => state
export const getReportFailedTransactions = (state: any) => state
export const getReportSystemErrorsandExceptions = (state: any) => state
export const getReportAuditTrail = (state: any) => state
export const getReportCME = (state: any) => state
export const getCashierTransactionReport = (state: any) => state
export const getCashierSummaryReport = (state: any) => state
export const getLimitActivityReport = (state: any) => state
export const getPlayerStatusReport = (state: any) => state
export const getPatronInfoReport = (state: any) => state
export const getTransactionSessionReport = (state: any) => state
