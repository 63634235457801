import { CustomHttpService } from './../../services/custom-http.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import store from 'store'
import { environment } from './../../../environments/environment'
import { Router } from '@angular/router'
import { ConfigService } from '../config.service'

@Injectable()
export class playerService {
  constructor(
    private http: CustomHttpService,
    private router: Router,
    private config: ConfigService,
  ) {}

  getPlayerList(params): Observable<any> {
    let filter = params.data.options
      ? params.data.options.filter.length
        ? params.data.options.filter
        : ''
      : 'customerid'
    let value = params.value || params.data.options.value || ''
    let startdate = params.data.options.startdate
    let enddate = params.data.options.enddate
    let page = params.data.pageIndex || 1
    let limit = params.data.pageSize || 10
    let sortColumn = params.data.sortColumn
    let sortType = params.data.sortType
    let parameters = `?filter=${filter}&value=${value}&startdate=${startdate}&enddate=${enddate}&page=${page}&limit=${limit}&sortColumn=${sortColumn}&sortType=${sortType}`

    return this.http.get(`Patron/PlayerList${parameters}`)
  }

  getPlayerDetails(params): Observable<any> {
    // return this.http.get(`dashboard/Patron/${params.data}`)
    return this.http.get(`Patron/GetPatronByPlayerId/${params.data}`)
  }

  updatePlayerDetails(params): Observable<any> {
    return this.http.put(`Patron/UpdatePatronInfo`, {
      playerId: params.playerId,
      firstName: params.firstName,
      lastName: params.lastName,
      address1: params.address1,
      email: params.email,
      mobileNo: params.mobileNo,
      status: params.status,
    })
  }

  updatePlayerLimits(id, gameType, params): Observable<any> {
    return this.http.put(`Patron/UpdatePatronLimits`, {
      playerId: id,
      dailyMaxAmount: params.dailyMaxAmount,
      monthlyMaxAmount: params.monthlyMaxAmount,
      gameTypeId: params.gameTypeId,
      gameType,
    })
  }

  updatePatronStatus(params): Observable<any> {
    return this.http.put(`Patron/UpdatePatronStatus`, {
      playerId: params.playerId,
      status: params.status,
    })
  }

  updatePatronExclusion(params): Observable<any> {
    return this.http.post('Patron/PatronSelfExclusion', {
      playerId: params.playerId,
      noOfDays: params.days,
    })
  }

  getPatronExisting(params): Observable<any> {
    return this.http.post('Patron/PatronExisting', params.list)
  }

  bulkBanned(params): Observable<any> {
    return this.http.put('Patron/BulkBanned', params.list)
  }

  getPlayerFeeInfo(params): Observable<any> {
    return this.http.post(`dashboard/Transaction`, {
      filter: 'CWA_PATRON_ID',
      value: params.data || '',
      startdate: '',
      enddate: '',
      page: '1',
      limit: '10',
    })
  }

  getPlayerLimits(params): Observable<any> {
    return this.http.get(`Patron/GetPatronLimitsById/${params.data}`)
  }

  // getPlayerMarkerInfo(params): Observable<any> {
  //   return this.http.get(`Dashboard/MarkerLimit/${params.data}`)
  // }

  makePayment(params): Observable<any> {
    const data = {
      amount: params.data.amount,
      method: params.data.method,
    }
    return this.http.post(
      `Dashboard/cwaDeposit/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }

  updateStatus(params): Observable<any> {
    return this.http.patch(`Identity/UpdatePlayerStatus`, params)
  }

  makeAdjustment(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      method: params.data.method,
    }
    return this.http.post(
      `Dashboard/cwaWithdraw/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }

  getPlayerAct(params): Observable<any> {
    return this.http.post(`dashboard/Transaction`, {
      filter: params.data.method,
      value: params.data.value || '',
      startdate: params.data.startdate,
      enddate: params.data.enddate,
      page: params.pagination.pageIndex,
      limit: params.data.limit || params.pagination.pageSize,
    })
  }

  getLimitAct(params): Observable<any> {
    return this.http.get(`Dashboard/GetAdvanceLineChangeByPlayerNumber/${params.data}`)
  }

  getFeeAct(params): Observable<any> {
    return this.http.get(`Dashboard/FeeActivity/${params.data}/10000/1`)
  }

  getPaymentAct(params): Observable<any> {
    if (params.data.api == 'All') params.data.api = 'PaymentActivity'
    else params.data.api = 'Payment/' + params.data.api
    return this.http.get(`Dashboard/${params.data.api}/${params.data.card}/10000/1`)
  }

  getStatementList(params): Observable<any> {
    return this.http.get(`Dashboard/Statement/${params.data}`)
  }

  playerAgreement(params): Observable<any> {
    const data = {
      sign_dt: params.data.date,
    }
    return this.http.post(
      `Dashboard/SignAgreement/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  generateAgreement(params): Observable<any> {
    return this.http.post(`Dashboard/GenerateAgreementV2/${params.data.playerCardNumber}`, {})
  }
  generateStatement(params): Observable<any> {
    return this.http.getv2(`Dashboard/Statement/Download/${params.data}`, {
      responseType: 'blob',
    })
  }
  previewAgreement(patronId): Observable<any> {
    // return this.http.postBlob(`Dashboard/PreviewAgreement/${params.data}`, JSON.stringify(params))
    return this.http.post(`Dashboard/PreviewAgreementV2/${patronId.data}`, {})
  }

  getCreditFactor(playerCardNumber): Observable<any> {
    return this.http.get(`Dashboard/CreditFactor/${playerCardNumber}`)
  }

  cashOut(params): Observable<any> {
    const data = {
      amount: parseFloat(params.data.amount),
      pin: params.data.pin,
    }
    return this.http.post(`Dashboard/cashout/${params.data.playerCardNumber}`, JSON.stringify(data))
  }
  internalAdjustment(params): Observable<any> {
    const data = {
      amount: params.data.amount,
      description: params.data.description,
      adjustmenttype: params.data.method,
    }
    return this.http.post(
      `Dashboard/adjustment/${params.data.playerCardNumber}`,
      JSON.stringify(data),
    )
  }
  getPatronStatus(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PatronStatusCount${param}`)
  }
  getTotalFee(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/TransactionCountTotalFee${param}`)
  }
  getPaymentInfo(params): Observable<any> {
    const param = params.from && params.to ? '/' + params.from + '/' + params.to : ''
    return this.http.get(`Dashboard/PaymentInformation${param}`)
  }
  getPlayerNotes(data, type?): Observable<any> {
    let params = ''
    if (data) params = `/${data.limit}/${data.page}`
    if (type == 1) {
      return this.http.post(`Dashboard/GetPatronNotifications${params}`, data.options)
    } else {
      return this.http.post(`Dashboard/Note`, data)
    }
  }
  addPlayerNotes(params): Observable<any> {
    return this.http.post(`Dashboard/CreateNote`, params)
  }
  editPlayerNotes(params): Observable<any> {
    return this.http.patch(`Identity/EditPatronNotes`, params)
  }
  deletePlayerNotes(Id): Observable<any> {
    return this.http.post(`Dashboard/DeleteNote`, { Id })
  }
  addMarkerLine(params): Observable<any> {
    return this.http.post(`Dashboard/InitiateAdvanceLineChange`, params)
  }
  getAdvanceLine(id): Observable<any> {
    return this.http.get(`Dashboard/GetAdvanceLineChange/${id}`)
  }
  updateAdLineStatus(params): Observable<any> {
    return this.http.post(`Dashboard/UpdateLineChangeRequest`, params)
  }
  getPatronNotif(params): Observable<any> {
    return this.http.get(`Dashboard/GetNotificationHistoryByLogId/${params.data}`)
  }
  getCMSStatus(params): Observable<any> {
    let cms = this.config.getCMS()
    let param = params.filter
      ? `?filter=CMS_CODE|${params.filter}:CMS_VERSION|${cms['version']}`
      : ''
    return this.http.get(`Dashboard/RetrieveCMSTransaction${param}`)
  }
  getWalletStatus(params): Observable<any> {
    let param = params.filter ? '?filter=WALLET_ID|' + params.filter : ''
    return this.http.get(`Dashboard/RetrieveWalletTransaction${param}`)
  }
  getCMSAmount(): Observable<any> {
    return this.http.get('Dashboard/RetrieveCMSTransactionAmount')
  }
  getWalletAmount(): Observable<any> {
    return this.http.get('Dashboard/RetrieveWalletTransactionAmount')
  }
}
