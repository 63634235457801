import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class ConfigService {
  private appConfig
  public metaConfig
  private CMSDetails = [
    {
      program: 'ardent',
      code: 'ARDENT',
      version: 'V1',
    },
    {
      program: 'ardent-bmm',
      code: 'ARDENT',
      version: 'V1',
    },
    {
      program: 'konami',
      code: 'SYNKROSV2',
      version: 'v2',
    },
    {
      program: 'acres',
      code: 'ACRESV3',
      version: 'v3',
    },
  ]

  constructor(private http: HttpClient) {}

  loadAppConfig() {
    return this.http
      .get('/assets/config/env.json')
      .toPromise()
      .then(data => {
        this.appConfig = data
        this.loadAppMetadata()
      })
      .catch((response: any) => {
        console.log(`Failed to load the config file`)
      })
  }

  getProgramVersion() {
    return this.appConfig.version
  }

  getVersion() {
    return '.0'
  }

  getConfig() {
    return this.appConfig ? this.appConfig : ''
  }

  getAppMetadata(): Observable<any> {
    return this.http.get('/assets/config/metadata.json')
  }

  getCMS() {
    let cms = [
      {
        program: '',
        code: '',
        version: '',
      },
    ]
    if (this.appConfig) {
      cms = this.CMSDetails.filter(c => {
        return c.program == this.appConfig.program
      })
    } else {
      cms = []
    }
    return cms ? cms[0] : []
  }

  loadAppMetadata() {
    return this.http
      .get('/assets/config/metadata.json')
      .toPromise()
      .then(data => {
        this.metaConfig = data
        this.loadStyle(this.metaConfig.client)
      })
      .catch((response: any) => {
        console.log(`Failed to load the config file`)
      })
  }
  getMetadataConfig() {
    return this.metaConfig ? this.metaConfig : ''
  }

  loadStyle(styleName: string) {
    const head = document.getElementsByTagName('head')[0]
    let themeLink = document.getElementById('client-theme') as HTMLLinkElement
    if (themeLink) {
      themeLink.href = `${styleName}.css`
    } else {
      const style = document.createElement('link')
      style.rel = 'stylesheet'
      style.href = `${styleName}.css`

      head.appendChild(style)
    }
  }
}
