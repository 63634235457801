import { BehaviorSubject, Observable, Subject } from 'rxjs'
import * as moment from 'moment'

export class ReportDate {
  _cutOffStartTime: Subject<any> = new BehaviorSubject<any>('')
  _cutOffEndTime: Subject<any> = new BehaviorSubject<any>('')

  constructor() {}

  setTime({ cutOffStartTime = '', cutOffEndTime = '' }) {
    this._cutOffStartTime.next(cutOffStartTime)
    this._cutOffEndTime.next(cutOffEndTime)
  }

  getTime() {
    let start, end
    this._cutOffStartTime.asObservable().subscribe(data => {
      start = data
    })
    this._cutOffEndTime.asObservable().subscribe(data => {
      end = data
    })
    return { start, end }
  }

  cutOffDate(startDate: any = '', endDate: any = '') {
    let { start, end } = this.getTime()
    if (!startDate) {
      return { start: '', end: '' }
    }
    if (!endDate) {
      endDate = moment(startDate).format('MMMM-DD-YYYY')
    }

    if (end < start) {
      endDate = moment(endDate)
        .add(1, 'd')
        .format('MMMM-DD-YYYY')
    }
    return { start: `${startDate} ${start}`, end: `${endDate} ${end}` }
  }
}
