import { AuditOutline } from '@ant-design/icons-angular/icons'
import { Action } from '@ngrx/store'

export const CMS_DETAILS = 'Get CMS Details'
export const CMS_DETAILS_SUCCESSFUL = 'Get CMS Details Successful'
export const CMS_DETAILS_UNSUCCESSFUL = 'Get CMS Details Unsuccessful'

export const REPORT_WAGERING_BALANCE_SUMMARY_OVERALL = 'Get ReportWageringBalanceSummaryOverall'
export const REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_SUCCESSFUL =
  'Get ReportWageringBalanceSummaryOverall Successful'
export const REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_UNSUCCESSFUL =
  'Get ReportWageringBalanceSummaryOverall Unsuccessful'

export const REPORT_WAGERING_DETAILED_REPORT = 'Get ReportWageringDetailedReport'
export const REPORT_WAGERING_DETAILED_REPORT_SUCCESSFUL =
  'Get ReportWageringDetailedReport Successful'
export const REPORT_WAGERING_DETAILED_REPORT_UNSUCCESSFUL =
  'Get ReportWageringDetailedReport Unsuccessful'

export const REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT = 'Get ReportWageringBalanceSummaryAccount'
export const REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_SUCCESSFUL =
  'Get ReportWageringBalanceSummaryAccount Successful'
export const REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_UNSUCCESSFUL =
  'Get ReportWageringBalanceSummaryAccount Unsuccessful'

export const REPORT_PLAYER_ACCOUNT_SUMMARY = 'Get ReportPlayerAccountSummary'
export const REPORT_PLAYER_ACCOUNT_SUMMARY_SUCCESSFUL = 'Get ReportPlayerAccountSummary Successful'
export const REPORT_PLAYER_ACCOUNT_SUMMARY_UNSUCCESSFUL =
  'Get ReportPlayerAccountSummary Unsuccessful'

export const REPORT_PLAYER_ACCOUNT_DETAILED = 'Get ReportPlayerAccountDetailed'
export const REPORT_PLAYER_ACCOUNT_DETAILED_SUCCESSFUL =
  'Get ReportPlayerAccountDetailed Successful'
export const REPORT_PLAYER_ACCOUNT_DETAILED_UNSUCCESSFUL =
  'Get ReportPlayerAccountDetailed Unsuccessful'

export const REPORT_PLAYER_ACTIVITY = 'Get ReportPlayerActivity'
export const REPORT_PLAYER_ACTIVITY_SUCCESSFUL = 'Get ReportPlayerActivity Successful'
export const REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL = 'Get ReportPlayerActivity Unsuccessful'

export const REPORT_LIABILITY = 'Get ReportLiability'
export const REPORT_LIABILITY_SUCCESSFUL = 'Get ReportLiability Successful'
export const REPORT_LIABILITY_UNSUCCESSFUL = 'Get ReportLiability Unsuccessful'

export const REPORT_LIABILITY_NEW = 'Get ReportLiabilityNew'
export const REPORT_LIABILITY_NEW_SUCCESSFUL = 'Get ReportLiabilityNew Successful'
export const REPORT_LIABILITY_NEW_UNSUCCESSFUL = 'Get ReportLiabilityNew Unsuccessful'

export const REPORT_CSD = 'Get ReportCSD'
export const REPORT_CSD_SUCCESSFUL = 'Get ReportCSD Successful'
export const REPORT_CSD_UNSUCCESSFUL = 'Get ReportCSD Unsuccessful'

export const REPORT_SA = 'Get ReportSA'
export const REPORT_SA_SUCCESSFUL = 'Get ReportSA Successful'
export const REPORT_SA_UNSUCCESSFUL = 'Get ReportSA Unsuccessful'

export const REPORT_LIMIT_ACTIVITY = 'Get ReportLimitActivity'
export const REPORT_LIMIT_ACTIVITY_SUCCESSFUL = 'Get ReportLimitActivity Successful'
export const REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL = 'Get ReportLimitActivity Unsuccessful'

export const REPORT_WAT_DETAIL = 'Get ReportWATDetail'
export const REPORT_WAT_DETAIL_SUCCESSFUL = 'Get ReportWATDetail Successful'
export const REPORT_WAT_DETAIL_UNSUCCESSFUL = 'Get ReportWATDetail Unsuccessful'

export const REPORT_WAT_SUMMARY_GAMING = 'Get ReportWATSummaryGaming'
export const REPORT_WAT_SUMMARY_GAMING_SUCCESSFUL = 'Get ReportWATSummaryGaming Successful'
export const REPORT_WAT_SUMMARY_GAMING_UNSUCCESSFUL = 'Get ReportWATSummaryGaming Unsuccessful'

export const REPORT_WAT_SUMMARY_OVERALL = 'Get ReportWATSummaryOverall'
export const REPORT_WAT_SUMMARY_OVERALL_SUCCESSFUL = 'Get ReportWATSummaryOverall Successful'
export const REPORT_WAT_SUMMARY_OVERALL_UNSUCCESSFUL = 'Get ReportWATSummaryOverall Unsuccessful'

export const REPORT_PLAYER_STATUS_CHANGE = 'Get ReportPlayerStatusChange'
export const REPORT_PLAYER_STATUS_CHANGE_SUCCESSFUL = 'Get ReportPlayerStatusChange Successful'
export const REPORT_PLAYER_STATUS_CHANGE_UNSUCCESSFUL = 'Get ReportPlayerStatusChange Unsuccessful'

export const REPORT_USER_ACCESS_LOGS = 'Get ReportUserAccessLogs'
export const REPORT_USER_ACCESS_LOGS_SUCCESSFUL = 'Get ReportUserAccessLogs Successful'
export const REPORT_USER_ACCESS_LOGS_UNSUCCESSFUL = 'Get ReportUserAccessLogs Unsuccessful'

export const REPORT_EL = 'Get ReportEL'
export const REPORT_EL_SUCCESSFUL = 'Get ReportEL Successful'
export const REPORT_EL_UNSUCCESSFUL = 'Get ReportEL Unsuccessful'

export const REPORT_WAT_IN_RECON = 'Get ReportWatInRecon'
export const REPORT_WAT_IN_RECON_SUCCESSFUL = 'Get ReportWatInRecon Successful'
export const REPORT_WAT_IN_RECON_UNSUCCESSFUL = 'Get ReportWatInRecon Unsuccessful'

export const REPORT_WAT_OUT_RECON = 'Get ReportWatOutRecon'
export const REPORT_WAT_OUT_RECON_SUCCESSFUL = 'Get ReportWatOutRecon Successful'
export const REPORT_WAT_OUT_RECON_UNSUCCESSFUL = 'Get ReportWatOutRecon Unsuccessful'

export const REPORT_FAILED_TRANSACTIONS = 'Get ReportFailedTransactions'
export const REPORT_FAILED_TRANSACTIONS_SUCCESSFUL = 'Get ReportFailedTransactions Successful'
export const REPORT_FAILED_TRANSACTIONS_UNSUCCESSFUL = 'Get ReportFailedTransactions Unsuccessful'

export const REPORT_ATR = 'Get AuditTrailReport'
export const REPORT_ATR_SUCCESSFUL = 'Get AuditTrailReport Successful'
export const REPORT_ATR_UNSUCCESSFUL = 'Get AuditTrailReport Unsuccessful'

export const REPORT_CME = 'Get CashlessMonthEndRecon'
export const REPORT_CME_SUCCESSFUL = 'Get CashlessMonthEndRecon Successful'
export const REPORT_CME_UNSUCCESSFUL = 'Get CashlessMonthEndRecon Unsuccessful'

export const REPORT_CASHIER_TRANSACTION = 'Get CashierTransactionReport'
export const REPORT_CASHIER_TRANSACTION_SUCCESSFUL = 'Get CashierTransactionReport Successful'
export const REPORT_CASHIER_TRANSACTION_UNSUCCESSFUL = 'Get CashierTransactionReport Unsuccessful'

export const REPORT_CASHIER_SUMMARY = 'Get CashierSummaryReport'
export const REPORT_CASHIER_SUMMARY_SUCCESSFUL = 'Get CashierSummaryReport Successful'
export const REPORT_CASHIER_SUMMARY_UNSUCCESSFUL = 'Get CashierSummaryReport Unsuccessful'

export const REPORT_SYSTEM_ERRORS_EXCEPTIONS = 'Get ReportSystemErrorsandExceptions'
export const REPORT_SYSTEM_ERRORS_EXCEPTIONS_SUCCESSFUL =
  'Get ReportSystemErrorsandExceptions Successful'
export const REPORT_SYSTEM_ERRORS_EXCEPTIONS_UNSUCCESSFUL =
  'Get ReportSystemErrorsandExceptions Unsuccessful'

export const REPORT_PATRON_INFO = 'Get ReportPatronInfo'
export const REPORT_PATRON_INFO_SUCCESSFUL = 'Get ReportPatronInfo Successful'
export const REPORT_PATRON_INFO_UNSUCCESSFUL = 'Get ReportPatronInfo Unsuccessful'

export const REPORT_TRANSACTION_SESSION = 'Get ReportTransactionSession'
export const REPORT_TRANSACTION_SESSION_SUCCESSFUL = 'Get ReportTransactionSession Successful'
export const REPORT_TRANSACTION_SESSION_UNSUCCESSFUL = 'Get ReportTransactionSession Unsuccessful'

export const RESET = 'Reset'

/* cms details */
export class CMSDetails implements Action {
  readonly type = CMS_DETAILS
  constructor(public payload: any) {}
}
export class CMSDetailsSuccess implements Action {
  readonly type = CMS_DETAILS_SUCCESSFUL
  constructor(public data: any) {}
}
export class CMSDetailsUnSuccess implements Action {
  readonly type = CMS_DETAILS_UNSUCCESSFUL
  constructor() {}
}
// Player Limit Report
export class LimitActivityReport implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY
  constructor(public payload: any) {}
}
export class LimitActivityReportSuccess implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class LimitActivityReportUnSuccess implements Action {
  readonly type = REPORT_LIMIT_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}
// player status change Report
export class PlayerStatusChangeReport implements Action {
  readonly type = REPORT_PLAYER_STATUS_CHANGE
  constructor(public payload: any) {}
}
export class PlayerStatusChangeReportSuccess implements Action {
  readonly type = REPORT_PLAYER_STATUS_CHANGE_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerStatusChangeReportUnSuccess implements Action {
  readonly type = REPORT_PLAYER_STATUS_CHANGE_UNSUCCESSFUL
  constructor() {}
}

// patron info Report
export class PatronInfoReport implements Action {
  readonly type = REPORT_PATRON_INFO
  constructor(public payload: any) {}
}
export class PatronInfoReportSuccess implements Action {
  readonly type = REPORT_PATRON_INFO_SUCCESSFUL
  constructor(public data: any) {}
}
export class PatronInfoReportUnSuccess implements Action {
  readonly type = REPORT_PATRON_INFO_UNSUCCESSFUL
  constructor() {}
}

// transaction session Report
export class TransactionSessionReport implements Action {
  readonly type = REPORT_TRANSACTION_SESSION
  constructor(public payload: any) {}
}
export class TransactionSessionReportSuccess implements Action {
  readonly type = REPORT_TRANSACTION_SESSION_SUCCESSFUL
  constructor(public data: any) {}
}
export class TransactionSessionReportUnSuccess implements Action {
  readonly type = REPORT_TRANSACTION_SESSION_UNSUCCESSFUL
  constructor() {}
}

/* wagering balance summary overall report */
export class ReportWageringBalanceSummaryOverall implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_OVERALL
  constructor(public payload: any) {}
}
export class ReportWageringBalanceSummaryOverallSuccess implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWageringBalanceSummaryOverallUnSuccess implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_OVERALL_UNSUCCESSFUL
  constructor() {}
}

/* wagering detailed report */
export class ReportWageringDetailedReport implements Action {
  readonly type = REPORT_WAGERING_DETAILED_REPORT
  constructor(public payload: any) {}
}
export class ReportWageringDetailedReportSuccess implements Action {
  readonly type = REPORT_WAGERING_DETAILED_REPORT_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWageringDetailedReportUnSuccess implements Action {
  readonly type = REPORT_WAGERING_DETAILED_REPORT_UNSUCCESSFUL
  constructor() {}
}

/* wagering balance summary account report */
export class ReportWageringBalanceSummaryAccount implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT
  constructor(public payload: any) {}
}
export class ReportWageringBalanceSummaryAccountSuccess implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWageringBalanceSummaryAccountUnSuccess implements Action {
  readonly type = REPORT_WAGERING_BALANCE_SUMMARY_ACCOUNT_UNSUCCESSFUL
  constructor() {}
}

/* player account summary report */
export class ReportPlayerAccountSummary implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_SUMMARY
  constructor(public payload: any) {}
}
export class ReportPlayerAccountSummarySuccess implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_SUMMARY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPlayerAccountSummaryUnSuccess implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_SUMMARY_UNSUCCESSFUL
  constructor() {}
}

/* player account detailed report */
export class ReportPlayerAccountDetailed implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_DETAILED
  constructor(public payload: any) {}
}
export class ReportPlayerAccountDetailedSuccess implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_DETAILED_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPlayerAccountDetailedUnSuccess implements Action {
  readonly type = REPORT_PLAYER_ACCOUNT_DETAILED_UNSUCCESSFUL
  constructor() {}
}

// Player Activity Report

export class ReportPlayerActivity implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY
  constructor(public payload: any) {}
}
export class ReportPlayerActivitySuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportPlayerActivityUnSuccess implements Action {
  readonly type = REPORT_PLAYER_ACTIVITY_UNSUCCESSFUL
  constructor() {}
}

/* Liability report */
export class ReportLiability implements Action {
  readonly type = REPORT_LIABILITY
  constructor(public payload: any) {}
}
export class ReportLiabilitySuccess implements Action {
  readonly type = REPORT_LIABILITY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportLiabilityUnSuccess implements Action {
  readonly type = REPORT_LIABILITY_UNSUCCESSFUL
  constructor() {}
}

/* Liability report new */
export class ReportLiabilityNew implements Action {
  readonly type = REPORT_LIABILITY_NEW
  constructor(public payload: any) {}
}
export class ReportLiabilityNewSuccess implements Action {
  readonly type = REPORT_LIABILITY_NEW_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportLiabilityNewUnSuccess implements Action {
  readonly type = REPORT_LIABILITY_NEW_UNSUCCESSFUL
  constructor() {}
}

/* CSD report */
export class ReportCSD implements Action {
  readonly type = REPORT_CSD
  constructor(public payload: any) {}
}
export class ReportCSDSuccess implements Action {
  readonly type = REPORT_CSD_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCSDUnSuccess implements Action {
  readonly type = REPORT_CSD_UNSUCCESSFUL
  constructor() {}
}

/* SA report */
export class ReportSA implements Action {
  readonly type = REPORT_SA
  constructor(public payload: any) {}
}
export class ReportSASuccess implements Action {
  readonly type = REPORT_SA_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportSAUnSuccess implements Action {
  readonly type = REPORT_SA_UNSUCCESSFUL
  constructor() {}
}

/* wat detail report */
export class ReportWATDetail implements Action {
  readonly type = REPORT_WAT_DETAIL
  constructor(public payload: any) {}
}
export class ReportWATDetailSuccess implements Action {
  readonly type = REPORT_WAT_DETAIL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWATDetailUnSuccess implements Action {
  readonly type = REPORT_WAT_DETAIL_UNSUCCESSFUL
  constructor() {}
}

/* wat summary gaming report */
export class ReportWATSummaryGaming implements Action {
  readonly type = REPORT_WAT_SUMMARY_GAMING
  constructor(public payload: any) {}
}
export class ReportWATSummaryGamingSuccess implements Action {
  readonly type = REPORT_WAT_SUMMARY_GAMING_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWATSummaryGamingUnSuccess implements Action {
  readonly type = REPORT_WAT_SUMMARY_GAMING_UNSUCCESSFUL
  constructor() {}
}

/* wat summary overall report */
export class ReportWATSummaryOverall implements Action {
  readonly type = REPORT_WAT_SUMMARY_OVERALL
  constructor(public payload: any) {}
}
export class ReportWATSummaryOverallSuccess implements Action {
  readonly type = REPORT_WAT_SUMMARY_OVERALL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWATSummaryOverallUnSuccess implements Action {
  readonly type = REPORT_WAT_SUMMARY_OVERALL_UNSUCCESSFUL
  constructor() {}
}

/* user access logs */
export class ReportUserAccessLogs implements Action {
  readonly type = REPORT_USER_ACCESS_LOGS
  constructor(public payload: any) {}
}
export class ReportUserAccessLogsSuccess implements Action {
  readonly type = REPORT_USER_ACCESS_LOGS_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportUserAccessLogsUnSuccess implements Action {
  readonly type = REPORT_USER_ACCESS_LOGS_UNSUCCESSFUL
  constructor() {}
}

// System Errors and Exceptions
export class ReportSystemErrorsandExceptions implements Action {
  readonly type = REPORT_SYSTEM_ERRORS_EXCEPTIONS
  constructor(public payload: any) {}
}
export class ReportSystemErrorsandExceptionsSuccess implements Action {
  readonly type = REPORT_SYSTEM_ERRORS_EXCEPTIONS_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportSystemErrorsandExceptionsUnSuccess implements Action {
  readonly type = REPORT_SYSTEM_ERRORS_EXCEPTIONS_UNSUCCESSFUL
  constructor() {}
}

/* EL report */
export class ReportEL implements Action {
  readonly type = REPORT_EL
  constructor(public payload: any) {}
}
export class ReportELSuccess implements Action {
  readonly type = REPORT_EL_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportELUnSuccess implements Action {
  readonly type = REPORT_EL_UNSUCCESSFUL
  constructor() {}
}
// Audit Trail Report
export class ReportAuditTrail implements Action {
  readonly type = REPORT_ATR
  constructor(public payload: any) {}
}
export class ReportAuditTrailSuccess implements Action {
  readonly type = REPORT_ATR_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportAuditTrailUnSuccess implements Action {
  readonly type = REPORT_ATR_UNSUCCESSFUL
  constructor() {}
}

/* WAT In Recon report */
export class ReportWatInRecon implements Action {
  readonly type = REPORT_WAT_IN_RECON
  constructor(public payload: any) {}
}
export class ReportWatInReconSuccess implements Action {
  readonly type = REPORT_WAT_IN_RECON_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWatInReconUnSuccess implements Action {
  readonly type = REPORT_WAT_IN_RECON_UNSUCCESSFUL
  constructor() {}
}

/* WAT Out Recon report */
export class ReportWatOutRecon implements Action {
  readonly type = REPORT_WAT_OUT_RECON
  constructor(public payload: any) {}
}
export class ReportWatOutReconSuccess implements Action {
  readonly type = REPORT_WAT_OUT_RECON_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportWatOutReconUnSuccess implements Action {
  readonly type = REPORT_WAT_OUT_RECON_UNSUCCESSFUL
  constructor() {}
}

/* Failed Transactions Report */
export class ReportFailedTransactions implements Action {
  readonly type = REPORT_FAILED_TRANSACTIONS
  constructor(public payload: any) {}
}
export class ReportFailedTransactionsSuccess implements Action {
  readonly type = REPORT_FAILED_TRANSACTIONS_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportFailedTransactionsUnSuccess implements Action {
  readonly type = REPORT_FAILED_TRANSACTIONS_UNSUCCESSFUL
  constructor() {}
}

// Caashier Transaction Report
export class ReportCashierTransactions implements Action {
  readonly type = REPORT_CASHIER_TRANSACTION
  constructor(public payload: any) {}
}
export class ReportCashierTransactionsSuccess implements Action {
  readonly type = REPORT_CASHIER_TRANSACTION_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCashierTransactionsUnSuccess implements Action {
  readonly type = REPORT_CASHIER_TRANSACTION_UNSUCCESSFUL
  constructor() {}
}

// Cashless Month End
export class ReportCashlessMonthEndRecon implements Action {
  readonly type = REPORT_CME
  constructor(public payload: any) {}
}
export class ReportCashlessMonthEndReconSuccess implements Action {
  readonly type = REPORT_CME_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCashlessMonthEndReconUnSuccess implements Action {
  readonly type = REPORT_CME_UNSUCCESSFUL
  constructor() {}
}

// Cashier Summary Report
export class ReportCashierSummary implements Action {
  readonly type = REPORT_CASHIER_SUMMARY
  constructor(public payload: any) {}
}
export class ReportCashierSummarySuccess implements Action {
  readonly type = REPORT_CASHIER_SUMMARY_SUCCESSFUL
  constructor(public data: any) {}
}
export class ReportCashierSummaryUnSuccess implements Action {
  readonly type = REPORT_CASHIER_SUMMARY_UNSUCCESSFUL
  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET
  constructor() {}
}

export type Actions =
  | CMSDetails
  | CMSDetailsSuccess
  | CMSDetailsUnSuccess
  | ReportWageringBalanceSummaryOverall
  | ReportWageringBalanceSummaryOverallSuccess
  | ReportWageringBalanceSummaryOverallUnSuccess
  | ReportWageringDetailedReport
  | ReportWageringDetailedReportSuccess
  | ReportWageringDetailedReportUnSuccess
  | ReportWageringBalanceSummaryAccount
  | ReportWageringBalanceSummaryAccountSuccess
  | ReportWageringBalanceSummaryAccountUnSuccess
  | ReportPlayerAccountSummary
  | ReportPlayerAccountSummarySuccess
  | ReportPlayerAccountSummaryUnSuccess
  | TransactionSessionReport
  | TransactionSessionReportSuccess
  | TransactionSessionReportUnSuccess
  | PatronInfoReport
  | PatronInfoReportSuccess
  | PatronInfoReportUnSuccess
  | ReportPlayerAccountDetailed
  | ReportPlayerAccountDetailedSuccess
  | ReportPlayerAccountDetailedUnSuccess
  | ReportPlayerActivity
  | ReportAuditTrail
  | ReportCashierTransactions
  | ReportCashierTransactionsSuccess
  | ReportCashierTransactionsUnSuccess
  | ReportCashlessMonthEndRecon
  | ReportCashlessMonthEndReconSuccess
  | ReportCashlessMonthEndReconUnSuccess
  | ReportAuditTrailSuccess
  | ReportAuditTrailUnSuccess
  | ReportPlayerActivitySuccess
  | ReportPlayerActivityUnSuccess
  | ReportSystemErrorsandExceptions
  | ReportSystemErrorsandExceptionsUnSuccess
  | ReportSystemErrorsandExceptionsSuccess
  | ReportLiability
  | ReportLiabilitySuccess
  | ReportLiabilityUnSuccess
  | ReportLiabilityNew
  | ReportLiabilityNewSuccess
  | ReportLiabilityNewUnSuccess
  | PlayerStatusChangeReport
  | PlayerStatusChangeReportSuccess
  | PlayerStatusChangeReportUnSuccess
  | ReportCSD
  | ReportCashierSummary
  | ReportCashierSummarySuccess
  | ReportCashierSummaryUnSuccess
  | ReportCSDSuccess
  | ReportCSDUnSuccess
  | LimitActivityReport
  | LimitActivityReportSuccess
  | LimitActivityReportUnSuccess
  | ReportSA
  | ReportSASuccess
  | ReportSAUnSuccess
  | ReportWATDetail
  | ReportWATDetailSuccess
  | ReportWATDetailUnSuccess
  | ReportWATSummaryGaming
  | ReportWATSummaryGamingSuccess
  | ReportWATSummaryGamingUnSuccess
  | ReportWATSummaryOverall
  | ReportWATSummaryOverallSuccess
  | ReportWATSummaryOverallUnSuccess
  | ReportUserAccessLogs
  | ReportUserAccessLogsSuccess
  | ReportUserAccessLogsUnSuccess
  | ReportEL
  | ReportELSuccess
  | ReportELUnSuccess
  | ReportWatInRecon
  | ReportWatInReconSuccess
  | ReportWatInReconUnSuccess
  | ReportWatOutRecon
  | ReportWatOutReconSuccess
  | ReportWatOutReconUnSuccess
  | ReportFailedTransactions
  | ReportFailedTransactionsSuccess
  | ReportFailedTransactionsUnSuccess
  | Reset
