<nz-sider nzCollapsible [nzWidth]="leftMenuWidth" [nzTrigger]="null" [nzCollapsible]="!isMobileView"
  [(nzCollapsed)]="isMenuCollapsed && !isMobileView" (nzCollapsedChange)="onCollapse($event)" [ngClass]="{
  menu: true,
  unfixed: isMenuUnfixed,
  shadow: isMenuShadow && !isMobileView
}">


  <i class="ant-layout-sider-trigger" nzTheme="outline" (click)="onCollapse(isMenuCollapsed = !isMenuCollapsed)">
    <img [src]="isMenuCollapsed ? '/assets/icons/icon-sider-right.svg' : '/assets/icons/icon-sider-left.svg'"/>
  </i>

  <div class="menuOuter" [ngStyle]="{
    width: isMenuCollapsed && !isMobileView ? '0' : leftMenuWidth + 'px',
    height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : '100%'
  }">

  <!-- SUBMENU  -->
    <!-- <div style="display: flex; height: 100%;">
      <div id="subMenu">
        <ul>
          <li (click)="setNav('home')"><i class="fe fe-home"></i></li>
          <li><i class="fe fe-search"></i></li>
        </ul>
        <ul>
          <li><i class="fe fe-bell"></i></li>
          <li routerLink="/help-tickets-management/help-tickets"><i class="fe fe-message-circle"></i></li>
          <li routerLink="/help-tickets-management/help-type"><i class="fe fe-help-circle"></i></li>
          <li (click)="setNav('settings')"><i class="fe fe-settings"></i></li> -->
        <!-- </ul>
      </div> -->
      <!-- end of sub menu -->
       <div id="mainMenu">
        <perfect-scrollbar >
          <ul nz-menu class="navigation" [nzInlineIndent]="15" [nzMode]="'inline'"
            [nzInlineCollapsed]="isMenuCollapsed && !isMobileView">
            <div class="menuInner">
              <!-- upper menu item -->
              <!-- <div class="logo"></div> -->
        
              <!-- <div>
                <li (click)="showSearchModal()" class="ant-menu-item">
                  <span [ngStyle]="{'padding-left.px': '0'}">
                    <i class="icon fe fe-search mr-2"></i>
                    <span class="title">Search</span>
                  </span>
                </li>
              </div>
 -->
              <div class="logoContainer">
                <div class="logo">
                  <img
                    (click)="redirect()"
                    src="./assets/images/logo/{{isMenuCollapsed && !isMobileView ? 'squared-logo' :  'logo-long' }}.png"
                    class="menu-logo" />
                </div>
              </div>
            <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: menuDataActivated, level: 0}"></ng-container>
            <ng-template #menuTpl let-menuDataActivated let-level="level">
              <!-- main menu items -->   
              <div class='middle-menu'>
                <ng-container *ngFor="let item of menuDataActivated">
                  <ng-container *ngIf="!item.roles || item.roles.includes(role)">
                    <ng-template #tooltipTpl>
                      <!-- {{ item.title }} -->
                    </ng-template>

                    <!-- group title item -->
                    <!-- <li nz-menu-group [nzTitle]="item.title" *ngIf="item.category"></li> -->
                    <!-- disabled item -->
                    <li nz-menu-item *ngIf="item.disabled" [nzSelected]="item.selected" [nzDisabled]="true" nz-tooltip
                      nzTooltipPlacement="right" [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''">
                      <div [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                        <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                        <img [src]="item.icon" [ngClass]="[item.icon, 'icon']"/>
                        <!-- <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i> -->
                        <span class="title">{{item.title}}</span>
                      </div>
                    </li>
                    <!-- main item -->
                    <!-- && permission.hasAccessTo(item.key,'R') -->
                    <li nz-menu-item *ngIf="!item.children && item.url && permission.hasAccessTo(item.key,'R') && item.position!='bottom'" routerLink={{item.url}} [nzSelected]="item.selected"
                      [nzDisabled]="item.disabled" nz-tooltip nzTooltipPlacement="right"
                      [nzTooltipTitle]="isMenuCollapsed && level === 0 ? item.title : ''">
                      <div [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                        <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                        <img class="item-icon" [src]="item.icon"/>
                        <!-- <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i> -->
                        <span class="title">{{item.title}}</span>
                      </div>
                    </li>

                    <!-- submenu item with recursive template -->
                    <li nz-submenu *ngIf="item.children" [nzOpen]="item.open && !isMenuCollapsed">
                      <div title [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                        <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                        <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                        <span class="title">{{item.title}}</span>
                      </div>
                      <ul>
                        <ng-container *ngTemplateOutlet="menuTpl; context: {$implicit: item.children, level: level + 1}">
                        </ng-container>
                      </ul>
                    </li>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
             <!-- lower menu items -->
              <div class='bottom-menu'>
                <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                [nzTooltipTitle]="isMenuCollapsed ? 'Change Password' : ''" routerLink="/change-password">
                  <span [ngStyle]="{'padding-left.px': '0'}">
                    <i class="icon fe fe-settings mr-2"></i>
                    <span class="title">{{ 'topBar.profileMenu.changePassword' | translate }}</span>
                  </span>
                </li> -->
                <li nz-menu-item nz-tooltip nzTooltipPlacement="right" *ngIf="permission.hasAccessTo('settings','R')"
                  [nzTooltipTitle]="isMenuCollapsed ? 'Settings' : ''" routerLink="/settings" [nzSelected]="settingsSelected">
                  <div [ngStyle]="{'padding-left.px': '0'}">
                    <img class="item-icon" src="/assets/icons/icon-settings.svg"/>

                    <!-- <i class="icon fe fe-settings mr-2"></i> -->
                    <span class="title">Settings</span>
                  </div>
                </li>

                <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
                [nzTooltipTitle]="isMenuCollapsed ? 'Logout' : ''" (click)="showConfirmation()">
                    <div [ngStyle]="{'padding-left.px': '0'}">
                      <img class="item-icon" src="/assets/icons/icon-logout.svg"/>
                      <span class="title">{{ 'topBar.profileMenu.logout' | translate }}</span>
                    </div>
                </li>
              </div>
            </div>
          </ul>
        </perfect-scrollbar>
      </div>
  </div>
</nz-sider>

<!-- Logout confirmation -->
<nz-modal
  nzWidth="500"
  nzVisible="{{ logoutConfirmationModal }}"
  class="fw-600"
  nzTitle="Logout"
  (nzOnCancel)="logoutConfirmationModal = false"
>
  <div nzModalContent class="text-center">
    <div class="font-size-16 text-dark">
      Are you sure you want to exit?
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" class="text-gray-7" (click)="logoutConfirmationModal = false">
      Cancel
    </button>
    <button nz-button nzType="primary" (click)="logout()">
      Yes
    </button>
  </div>
</nz-modal>