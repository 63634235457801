<div class="topbar">
  <div class="mr-auto" id="top-searchbar">
    <div class="logoContainer">
      <div class="logo2"></div>
    </div>
    <!-- <cui-topbar-search></cui-topbar-search> -->
  </div>
  <div *ngIf="env && env!='prod'">
    <div class="environment-badge {{env}}">{{env}}</div>
  </div>
  <!-- <div class="mr-4 d-none d-sm-block">
    <cui-topbar-actions></cui-topbar-actions>
  </div> -->
  <div>
    <cui-topbar-user-menu></cui-topbar-user-menu>
  </div>
</div>