import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import session from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as act from './actions'
import { playerService } from 'src/app/services/player'
import * as moment from 'moment'
import { CurrencyPipe } from '@angular/common'
import { MiscFunctions } from 'src/app/misc'

@Injectable()
export class PlayertEffects {
  playerList: [] = []
  playerDetails: [] = []
  playerLimits: [] = []
  playerUpdateDetails: any
  playerFeeInfo: [] = []
  playerMarkerInfo: [] = []
  playerActList: [] = []
  limitActList: [] = []
  PatronNotifList: [] = []
  feeActList: [] = []
  paymentActList: [] = []

  paymentSuccess: [] = []
  adjustmentSucess: [] = []

  creditFactor: [] = []
  constructor(
    private actions: Actions,
    private playerService: playerService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    public currencyPipe: CurrencyPipe,
    public misc: MiscFunctions,
  ) {}

  @Effect()
  getPlayerList: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_LIST),
    map((action: act.PlayerList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.playerService.getPlayerList(payload).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.status = v.status.toLowerCase()
            return v
          })
          this.playerList = []
          this.playerList['PlayerList'] = res.data
          return new act.PlayerListSuccess(this.playerList)
        }),
      )
    }),
  )

  @Effect()
  getPlayerDetails: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_DETAILS),
    map((action: act.PlayerDetails) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([playerData, settings]) => {
      return this.playerService.getPlayerDetails(playerData).pipe(
        map(res => {
          res.data = res.data.map(v => {
            return v
          })
          this.playerDetails = []
          this.playerDetails['PlayerDetails'] = res
          return new act.PlayerListSuccess(this.playerDetails)
        }),
      )

      // return this.playerService.getPlayerDetails(playerData).pipe(
      //   map(res => {
      //     let data = res.data
      //     res.PlayerDetails = data.map(v => {
      //       v.BirthDate = moment(v.BirthDate).format('YYYY-MM-DD hh:mm A')
      //       v.modified = moment(v.modified).format('YYYY-MM-DD hh:mm A')
      //       return v
      //     })
      //     return new act.PlayerDetailsSuccess(res)
      //   }),
      // )
    }),
  )

  @Effect()
  getPlayerLimits: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_LIMITS),
    map((action: act.PlayerLimits) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([playerData, settings]) => {
      return this.playerService.getPlayerLimits(playerData).pipe(
        map(res => {
          res.data = res.data.map(v => {
            v.dailyMaxAmount = this.currencyPipe.transform(
              v.dailyMaxAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            v.monthlyMaxAmount = this.currencyPipe.transform(
              v.monthlyMaxAmount,
              'USD',
              'symbol',
              '1.2-2',
            )
            return v
          })
          this.playerLimits = []
          this.playerLimits['PlayerLimits'] = res
          return new act.PlayerListSuccess(this.playerLimits)
        }),
      )
    }),
  )

  @Effect()
  getPlayerFeeInfo: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_FEE_INFO),
    map((action: act.PlayerFeeInfo) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([playerFee, settings]) => {
      return this.playerService.getPlayerFeeInfo(playerFee).pipe(
        map(res => {
          res.PatronFee = res.data
          return new act.PlayerFeeInfoSuccess(res)
        }),
      )
    }),
  )

  // @Effect()
  // getPlayerMarkerInfo: Observable<any> = this.actions.pipe(
  //   ofType(act.PLAYER_MARKER_INFO),
  //   map((action: act.PlayerMarkerInfo) => action),
  //   concatMap(action =>
  //     of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
  //   ),
  //   switchMap(([playerMarker, settings]) => {
  //     return this.playerService.getPlayerMarkerInfo(playerMarker).pipe(
  //       map(res => {
  //         if (res.data) {
  //           res.data = res.data.map(v => {
  //             v.created = moment(v.created).format('YYYY-MM-DD hh:mm A')
  //             v.modified = moment(v.modified).format('YYYY-MM-DD hh:mm A')
  //             v.availableLimitCurrency = this.currencyPipe.transform(
  //               v.availableLimit,
  //               'USD',
  //               'symbol',
  //               '1.2-2',
  //             )
  //             v.surplus_amountCurrency = this.currencyPipe.transform(
  //               v.surplus_amount,
  //               'USD',
  //               'symbol',
  //               '1.2-2',
  //             )
  //             return v
  //           })
  //         }
  //         this.playerMarkerInfo = []
  //         this.playerMarkerInfo['playerMarkerInfo'] = res.data ? res.data : []
  //         return new act.PlayerMarkerInfoSuccess(this.playerMarkerInfo)
  //       }),
  //       catchError(error => {
  //         // let err_msg = error.errors ? error.errors.message : error.message
  //         // this.notification.warning('Error', `${error.error}`)
  //         return from([{ type: act.PLAYER_MARKER_INFO_UNSUCCESSFUL }])
  //       }),
  //     )
  //   }),
  // )

  @Effect()
  makePayment: Observable<any> = this.actions.pipe(
    ofType(act.MAKE_PAYMENT),
    map((action: act.MakePayment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.makePayment(data).pipe(
        map(res => {
          res.paymentSuccess = res.data
          this.notification.success('Success', `Payment Successful`)
          return new act.MakePaymentSuccess(res)
          // return new act.PlayerMarkerInfo(data.data.playerCardNumber)
        }),
        catchError(error => {
          let err_msg = error.error.message
          this.notification.warning('Error', `${err_msg}`)
          return from([{ type: act.MAKE_PAYMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  makeAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.MAKE_ADJUSTMENT),
    map((action: act.MakeAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.makeAdjustment(data).pipe(
        map(res => {
          res.adjustmentSucess = res
          this.notification.success('Success', `Payment Successful`)
          // return new act.MakeAdjustmentSuccess(res)
          return new act.PlayerMarkerInfo(data.data.playerCardNumber)
        }),
        catchError(error => {
          let err_msg = error.error.message
          this.notification.warning('Error', `${err_msg}`)
          return from([{ type: act.MAKE_ADJUSTMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getPlayerActList: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_ACT_LIST),
    map((action: act.PlayerActList) => {
      return action
    }),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getPlayerAct(data).pipe(
        map(res => {
          res.playerActList = res.data.map(v => {
            v.TRANSACTION_CURRENT_BALANCE = this.misc.transformCurrency(
              v.TRANSACTION_CURRENT_BALANCE,
            )
            v.TRANSACTION_AMOUNT = this.misc.transformCurrency(v.TRANSACTION_AMOUNT)
            v.TRANSACTION_CREATE_DATE = moment(new Date(v.TRANSACTION_CREATE_DATE)).format(
              'YYYY-MM-DD hh:mm:ss A',
            )

            return v
          })
          return new act.PlayerActListSuccess(res)
        }),
      )
    }),
  )

  @Effect()
  getLimitActList: Observable<any> = this.actions.pipe(
    ofType(act.LIMIT_ACT_LIST),
    map((action: act.LimitActList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getLimitAct(data).pipe(
        map(res => {
          res.limitActList = res.data.map(v => {
            v.DateCreated = moment(v.DateCreated).format('YYYY-MM-DD hh:mm:ss A')
            v.ApprovedAmountCurrency = this.misc.transformCurrency(v.ApprovedAmount)
            v.RequestedAmountCurrency = this.misc.transformCurrency(v.RequestedAmount)
            v.ApprovedAmount = this.misc.transformCurrency(v.ApprovedAmount, 1)
            v.RequestedAmount = this.misc.transformCurrency(v.RequestedAmount, 1)
            return v
          })
          return new act.LimitActListSuccess(res)
        }),
      )
    }),
  )

  @Effect()
  getFeeActList: Observable<any> = this.actions.pipe(
    ofType(act.FEE_ACT_LIST),
    map((action: act.FeeActList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getFeeAct(data).pipe(
        map(res => {
          res.feeActList = res.data
            .filter(x => x.FeeType !== 'Scoring fee assessed to casino')
            .map(v => {
              v.Date = moment(v.Date).format('YYYY-MM-DD hh:mm:ss A')
              v.BaseAmountCurrency = this.currencyPipe.transform(
                v.BaseAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              v.FeeAmountCurrency = this.currencyPipe.transform(
                v.FeeAmount,
                'USD',
                'symbol',
                '1.2-2',
              )
              return v
            })

          return new act.FeeActListSuccess(res)
        }),
      )
    }),
  )

  @Effect()
  getPaymentAct: Observable<any> = this.actions.pipe(
    ofType(act.PAYMENT_ACT_LIST),
    map((action: act.PaymentActList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getPaymentAct(data).pipe(
        map(res => {
          res.paymentActList = res.data.map(v => {
            v.datetime = moment(v.created).format('YYYY-MM-DD hh:mm:ss A')
            v.created = moment(v.created).format('YYYY-MM-DD hh:mm:ss A')
            v.modified = moment(v.modified).format('YYYY-MM-DD hh:mm A')
            v.debitCurrency = this.currencyPipe.transform(v.debit, 'USD', 'symbol', '1.2-2')
            v.creditCurrency = this.currencyPipe.transform(v.credit, 'USD', 'symbol', '1.2-2')

            return v
          })
          return new act.PaymentActListSuccess(res)
        }),
      )
    }),
  )

  @Effect()
  getStatement: Observable<any> = this.actions.pipe(
    ofType(act.STATEMENT),
    map((action: act.StatementList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getStatementList(data).pipe(
        map(res => {
          res.statement = res.data.map(v => {
            v.PS_START_DATE = moment(v.PS_START_DATE).format('YYYY-MM-DD hh:mm A')
            v.PS_END_DATE = moment(v.PS_END_DATE).format('YYYY-MM-DD hh:mm A')
            v.PS_DUE_DATE = moment(v.PS_DUE_DATE).format('YYYY-MM-DD hh:mm A')
            v.PS_CREATE_DATE = moment(v.PS_CREATE_DATE).format('YYYY-MM-DD hh:mm A')
            return v
          })
          return new act.StatementListSuccess(res)
        }),
      )
    }),
  )

  @Effect()
  playerAgreement: Observable<any> = this.actions.pipe(
    ofType(act.PLAYER_AGREEMENT),
    map((action: act.PlayerAgreement) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.playerAgreement(data).pipe(
        map(res => {
          res.PlayerAgreement = res
          this.notification.success('Marker Agreement', `${res.message}`)
          return new act.PlayerMarkerInfo(data.data.playerCardNumber)
        }),
        catchError(error => {
          this.notification.warning('Error', `${error.errors.message}`)
          return from([{ type: act.PLAYER_AGREEMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  generateAgreement: Observable<any> = this.actions.pipe(
    ofType(act.GENERATE_AGREEMENT),
    map((action: act.GenerateAgreement) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.generateAgreement(data).pipe(
        map(res => {
          res.AgreementData = res.data[0].Agreement
          return new act.GenerateAgreementSuccess(res)
        }),
        catchError(error => {
          this.notification.warning('Error', `${error}`)
          return from([{ type: act.GENERATE_AGREEMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  generateStatement: Observable<any> = this.actions.pipe(
    ofType(act.GENERATE_STATEMENT),
    map((action: act.GenerateAgreement) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.generateStatement(data).pipe(
        map(res => {
          res.StatementData = res
          return new act.GenerateStatementSuccess(res)
        }),
        catchError(error => {
          return from([{ type: act.GENERATE_AGREEMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  previewAgreement: Observable<any> = this.actions.pipe(
    ofType(act.PREVIEW_AGREEMENT),
    map((action: act.PreviewAgreement) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.previewAgreement(data).pipe(
        map(res => {
          res.PreviewAgreement = res.data[0].Agreement
          return new act.PreviewAgreementSuccess(res)
        }),
        catchError(error => {
          this.notification.warning('Error', 'Something went wrong')
          return from([{ type: act.PREVIEW_AGREEMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getCreditFactor: Observable<any> = this.actions.pipe(
    ofType(act.CREDIT_FACTOR),
    map((action: act.CreditFactor) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([playerCardNumber, settings]) => {
      return this.playerService.getCreditFactor(playerCardNumber).pipe(
        map(res => {
          if (res.data) {
            res.data = res.data.map(v => {
              v.CreditFactorCreateDate = moment(v.CreditFactorCreateDate).format(
                'YYYY-MM-DD hh:mm A',
              )
              v.CreditFactorUpdateDate = moment(v.CreditFactorUpdateDate).format(
                'YYYY-MM-DD hh:mm A',
              )
              return v
            })
          }
          this.creditFactor = [] // reset
          this.creditFactor['creditFactor'] = res.data ? res.data.reverse() : []
          return new act.CreditFactorSuccess(this.creditFactor, '')
        }),
        catchError(error => {
          if (error.error.message) {
            let err = error.error
            this.notification.warning('Auth Failed', `${err.message}`)
          } else {
            let err = JSON.parse(error.error)
            this.notification.warning('Auth Failed', `${err.message}`)
          }
          return from([{ type: act.CREDIT_FACTOR_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  cashOut: Observable<any> = this.actions.pipe(
    ofType(act.CASHOUT),
    map((action: act.CashOut) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.cashOut(data).pipe(
        map(res => {
          res.cashoutSuccess = res.data
          this.notification.success('Success', res.message)
          return new act.CashOutSuccess(res)
          // return new act.PlayerMarkerInfo(data.data.playerCardNumber)
        }),
        catchError(error => {
          if (error) {
            let err_msg = error.error.message
            this.notification.warning('Error', `${err_msg}`)
            return from([{ type: act.CASHOUT_UNSUCCESSFUL }])
          }
        }),
      )
    }),
  )

  @Effect()
  internalAdjustment: Observable<any> = this.actions.pipe(
    ofType(act.INTERNAL_ADJUSTMENT),
    map((action: act.InternalAdjustment) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.internalAdjustment(data).pipe(
        map(res => {
          res.internalAdjustmentSucess = res.data
          this.notification.success('Success', res.message)
          return new act.InternalAdjustmentSuccess(res)
        }),
        catchError(error => {
          let err_msg = error.error.message
          this.notification.warning('Error', `${err_msg}`)
          return from([{ type: act.INTERNAL_ADJUSTMENT_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  getPatronNotifHistory: Observable<any> = this.actions.pipe(
    ofType(act.PATRON_NOTIF_LIST),
    map((action: act.PatronNotifList) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.playerService.getPatronNotif(data).pipe(
        map(res => {
          res.PatronNotifList = res.Data.map(v => {
            v.date = moment(v.date).format('YYYY-MM-DD hh:mm:ss A')

            return v
          })
          return new act.PatronNotifListSuccess(res)
        }),
      )
    }),
  )
}
