<div>
  <div nz-dropdown nzTrigger="click" (click)="refreshName()" class="dropdown" [nzDropdownMenu]="menu" >
    <nz-badge>
      <nz-avatar nzIcon="user" [nzSize]="'large'" class="kit__utils__avatar--roounded mx-2"></nz-avatar>
    </nz-badge>
    <span class="mx-2">Profile</span>

  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item>
        <strong>{{ 'topBar.profileMenu.hello' | translate }}, {{name || 'Anonymous'}}</strong>
        <div>
          <strong>
            {{ 'topBar.profileMenu.role' | translate }}:
          </strong>
            {{ role || '—'}}
        </div>
      </li>
      <li nz-menu-divider></li>
      <li nz-menu-item>
        <a routerLink="/profile">
          {{ 'topBar.profileMenu.editProfile' | translate }}
        </a>
      </li>
      <li nz-menu-item>
        <a routerLink="/change-password">
          Change {{settings['PASSWORD_LABEL']}}
        </a>
      </li>
      <li nz-menu-divider></li>

      <li nz-menu-item (click)="showConfirmation()">

        <a href="javascript: void(0);" >
          {{ 'topBar.profileMenu.logout' | translate }}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</div>

<!-- Logout confirmation -->
<nz-modal
  nzWidth="500"
  nzVisible="{{ logoutConfirmationModal }}"
  class="fw-600"
  nzTitle="Logout"
  (nzOnCancel)="logoutConfirmationModal = false"
>
  <div nzModalContent class="text-center">
    <div class="font-size-16 text-dark">
      Are you sure you want to exit?
    </div>
  </div>
  <div *nzModalFooter>
    <button nz-button nzType="default" class="text-gray-7" (click)="logoutConfirmationModal = false">
      Cancel
    </button>
    <button nz-button nzType="primary" (click)="logout()">
      Yes
    </button>
  </div>
</nz-modal>