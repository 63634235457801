import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core'
import { Keepalive } from '@ng-idle/keepalive'
import { LocalService } from './local.service'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { jwtAuthService } from './jwt'
import { NzNotificationService } from 'ng-zorro-antd'

@Injectable()
export class IdleTimeoutService {
  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private localService: LocalService,
    private router: Router,
    private jwtAuthService: jwtAuthService,
    private notification: NzNotificationService,
  ) {
    this.watcherStart = this.idle.onIdleStart.subscribe(async () => {
      this.idleState = "You've gone idle!"
    })

    this.watcherEnd = this.idle.onIdleEnd.subscribe(async () => {
      this.idleState = 'No longer this idle.'
      this.reset()
    })

    this.watcherTimeout = this.idle.onTimeout.subscribe(async () => {
      this.idleState = 'Timed out!'
      // this.jwtAuthService.logout()
      this.localService.setJsonValue('sessionId', '')
      this.localService.setJsonValue('userInfo', '')
      this.router.navigate(['/auth/login']).then(() => {
        localStorage.setItem('timeout', 'true')
        window.location.reload()
      })
    })

    this.watcherWarning = this.idle.onTimeoutWarning.subscribe(async countdown => {
      if (this.idle.getTimeout() * 0.2 == countdown) {
        this.notification.warning(
          'Timeout',
          `You will be logged out in ${this.convertSeconds(countdown)}`,
          { nzKey: 'timeout' },
        )
      }
    })
  }

  idleState = ''
  lastPing?: Date = null
  watcherStart: any
  watcherEnd: any
  watcherTimeout: any
  watcherWarning: any

  reset() {
    this.idle.watch()
  }

  idleStop() {
    this.idle.stop()
  }

  async setIdle(timer) {
    this.idle.setIdle(2)
    this.idle.setTimeout(parseInt(timer) * 60)
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES)

    this.keepalive.interval(15)
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()))

    if (this.localService.getJsonValue('userInfo')) {
      this.reset()
    } else {
      this.idleStop()
    }
  }

  convertSeconds(seconds) {
    let minutes = seconds / 60
    let hours = minutes / 60

    if (minutes < 1) {
      return `${seconds} seconds`
    } else {
      if (hours < 1) {
        return `${parseInt(minutes.toFixed(0))} ${minutes > 1 ? 'minutes' : 'minute'}`
      } else {
        return `${parseInt(hours.toFixed(0))} ${hours > 1 ? 'hours' : 'hour'}${
          minutes % 60 == 0 ? '.' : ` and ${minutes % 60} ${minutes > 1 ? 'minutes' : 'minute'}.`
        }`
      }
    }
  }
}
